import { forwardRef, type ElementType, type HTMLAttributes } from 'react';
import type { CommonHTMLAttributes } from '@frontend/types';
import type { FontColorType } from '../../helpers';
import { useStyles } from '../../use-styles';
import type { FontWeight, TextAlignment, TextSize } from './text-style-helpers';

type DragProps = Pick<HTMLAttributes<HTMLElement>, 'draggable' | 'onDragStart' | 'onDragEnd'>;

export type TextProps = CommonHTMLAttributes & {
  color?: FontColorType;
  as?: ElementType;
  size?: TextSize;
  textAlign?: TextAlignment;
  weight?: FontWeight;
} & DragProps;

export const Text = forwardRef<HTMLElement, TextProps>(
  (
    { size = 'large', weight = 'inherit', color = 'default', textAlign, as = 'p', className, children, ...props },
    ref
  ) => {
    const Component = as;
    const textStyle = useStyles('Text', { size, weight, color, textAlign });
    return (
      <Component ref={ref} className={className} css={textStyle} {...props}>
        <>{children}</>
      </Component>
    );
  }
);

Text.displayName = 'Text';
