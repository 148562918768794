import type { CommonHTMLAttributes } from '@frontend/types';
import { useStyles } from '../../use-styles';
import { DotProps } from './types';

export type ColoredDotProps = Omit<CommonHTMLAttributes, 'color'> & {
  children?: React.ReactNode;
  hoverText?: string;
};

export const Dot: React.FC<React.PropsWithChildren<DotProps>> = ({ color, innerRef, pulsing = false, ...rest }) => {
  const dotStyle = useStyles('Dot', { color, pulsing });

  return <div css={dotStyle} ref={innerRef} {...rest} />;
};
