import React, { memo } from 'react';
import { ListboxLi } from './listbox-li.component';
import { useListboxContext } from './listbox.provider';

export type ListboxOptionProps = {
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  /** String for searching against (if children is jsx or varies from the value) */
  searchValue?: string;
  value: string;
  trackingId?: string;
};

export const ListboxOption = memo(({ disabled, searchValue, value, ...rest }: ListboxOptionProps) => {
  const { getOptionProps } = useListboxContext();
  const props = getOptionProps({ disabled, value });
  return <ListboxLi {...rest} {...props} />;
});
