import { ElementType, ReactNode, FC } from 'react';
import type { CommonHTMLAttributes } from '@frontend/types';
import { useActiveTab } from './tabs.provider';

type Props = Omit<CommonHTMLAttributes, 'id'> & {
  as?: ElementType | FC<React.PropsWithChildren<unknown>>;
  children: ReactNode;
  controller: string;
  /**
   * This field is deprecated and will be removed in future versions.
   * @deprecated Deriving this id value now from the `controller` prop.
   */
  id?: string;
  keepMounted?: boolean;
};

export const TabPanel = ({ as = 'section', controller, id, keepMounted = true, ...rest }: Props) => {
  const { activeTab } = useActiveTab();
  const hidden = activeTab !== controller;
  const Component = as;

  if (!keepMounted && hidden) {
    return null;
  }

  return (
    <Component
      {...rest}
      role='tabpanel'
      hidden={hidden}
      aria-labelledby={controller}
      id={id ?? `${controller}-panel`}
    />
  );
};
