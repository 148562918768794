import { create } from 'zustand';
import { breakpoints, NavSizes } from './breakpoints';
import { useMatchMedia } from './use-match-media';
import { useWindowDimensions } from './use-window-dimensions';

export const useMediaMatches = create(() => ({
  matches: {
    useXSmallMax: () => useMatchMedia({ maxWidth: breakpoints.xsmall.max }),
    useSmallMin: () => useMatchMedia({ minWidth: breakpoints.small.min }),
    useSmallMax: () => useMatchMedia({ minWidth: breakpoints.small.max }),
    useSmallMinMax: () =>
      useMatchMedia({
        minWidth: breakpoints.small.min,
        maxWidth: breakpoints.small.max,
      }),
    useMediumMin: () => useMatchMedia({ minWidth: breakpoints.medium.min }),
    useMediumMax: () => useMatchMedia({ minWidth: breakpoints.medium.max }),
    useMediumMinMax: () =>
      useMatchMedia({
        minWidth: breakpoints.medium.min,
        maxWidth: breakpoints.medium.max,
      }),
    useMobileMin: () => useMatchMedia({ minWidth: breakpoints.mobile.min }),
    useMobileMax: () => useMatchMedia({ maxWidth: breakpoints.mobile.max }),
    useLargeMin: () => useMatchMedia({ minWidth: breakpoints.large.min }),
    useLargeMax: () => useMatchMedia({ maxWidth: breakpoints.large.max }),
    useLargeMinMax: () =>
      useMatchMedia({
        minWidth: breakpoints.large.min,
        maxWidth: breakpoints.large.max,
      }),
  },
}));

export const useBreakpoint = (): NavSizes => {
  const { width } = useWindowDimensions();

  const isXsmall = width <= breakpoints.xsmall.max;
  if (isXsmall) {
    return 'xsmall';
  }

  const isSmall = width <= breakpoints.small.max;
  if (isSmall) {
    return 'small';
  }

  const isMedium = width <= breakpoints.medium.max;
  if (isMedium) {
    return 'medium';
  }

  const isMobile = width <= breakpoints.mobile.max;
  if (isMobile) {
    return 'mobile';
  }

  const isLarge = width <= breakpoints.large.max;
  if (isLarge) {
    return 'large';
  }
  return 'xlarge';
};
