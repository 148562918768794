import { forwardRef } from 'react';
import { css } from '@emotion/react';
import type { CommonHTMLAttributes } from '@frontend/types';
import { usePortal, useThemeValues } from '../../hooks';
import type { HintProps } from './use-hint';

type HintComponentProps = HintProps & {
  children: React.ReactNode;
  hasInteractiveContent?: boolean;
};

export type HintUIProps = Omit<HintComponentProps, 'ref'> & CommonHTMLAttributes;

export const Hint = forwardRef<HTMLParagraphElement, HintUIProps>(
  ({ buffer, children, hasInteractiveContent, id, placement, ...rest }, ref) => {
    const { Portal } = usePortal({
      attributes: { id, style: 'width: 0; height: 0' },
    });
    const visible = !rest['aria-hidden'];
    const theme = useThemeValues();

    return (
      <Portal>
        <p
          css={[
            css`
              background: white;
              border-radius: ${theme.borderRadius.small};
              font-family: ${theme.font.family};
              font-size: ${theme.font.size.large};
              line-height: 1.3;
              margin: 0;
              opacity: ${visible ? 1 : 0};
              padding: ${theme.spacing(1)};
              pointer-events: none;
              position: absolute;
              transition: opacity 250ms ease-in-out;

              :focus {
                outline: none;
              }
            `,
            hasInteractiveContent &&
              visible &&
              css`
                pointer-events: all;
              `,
          ]}
          {...rest}
          ref={ref}
        >
          {children}
        </p>
      </Portal>
    );
  }
);
Hint.displayName = 'Hint';
