import { ReactNode, useEffect, useRef } from 'react';
import { css } from '@emotion/react';
import { isFunction } from 'lodash-es';
import { IconName } from '@frontend/icons';
import { mergeClasses } from '../../../helpers/merge-classes';
import { useThemeValues } from '../../../hooks/use-theme-values';
import { styles } from '../../../styles';
import { Button } from '../../button';
import { Text } from '../../text';
import { TextLink } from '../../text-link/text-link.component';
import { useModalContext } from '../provider/modal.provider';
import { withClose, type ClickHandler } from './helpers';

type Props = {
  className?: string;
  showPrimary?: boolean;
  disablePrimary?: boolean;
  disableSecondary?: boolean;
  onBackClick?: ClickHandler;
  onPrimaryClick?: 'submit' | ClickHandler;
  onSecondaryClick?: ClickHandler;
  backLabel?: ReactNode;
  primaryLabel?: string;
  secondaryLabel?: string;
  backTrackingId?: string;
  primaryTrackingId?: string;
  secondaryTrackingId?: string;
  destructive?: boolean;
  helperText?: string;
  primaryLoading?: boolean;
  secondaryLoading?: boolean;
  tertiaryLoading?: boolean;
};

/**
 * @deprecated Use ModalFooter instead
 * Component for adding secondary + primary actions to a modal
 * @param {string} [props.className] Optional css/classname
 * @param {boolean} [props.disablePrimary] Should the primary button be disabled?
 * @param {boolean} [props.disableSecondary] Should the secondary button be disabled?
 * @param {string | function} props.onPrimaryClick Either 'submit' (for buttons controlling a form in a modal) or a click handler function.
 * @param {function} [props.onSecondaryClick] Optionals click handler for the secondary action. Defaults to the modals onClose function.
 * @param {string} [props.primaryLabel] Optional text for the primary button (default = 'Save')
 * @param {string} [props.secondaryLabel] Optional text for the secondary button (default = 'Cancel')
 */
export const ModalActions = ({
  disablePrimary,
  disableSecondary,
  showPrimary = true,
  primaryLabel = 'Save',
  secondaryLabel = 'Cancel',
  backLabel,
  onBackClick,
  onSecondaryClick,
  onPrimaryClick,
  backTrackingId,
  primaryTrackingId,
  secondaryTrackingId,
  destructive,
  helperText,
  primaryLoading,
  secondaryLoading,
  tertiaryLoading,
}: Props) => {
  const isForm = !isFunction(onPrimaryClick);
  const { setActionStateChanged, onClose } = useModalContext();
  const primaryDisabled = useRef(disablePrimary);
  const secondaryDisabled = useRef(disableSecondary);

  useEffect(() => {
    if (import.meta.env.MODE !== 'production') {
      console.warn('ModalActions is deprecated. Use ModalFooter instead');
    }
  }, []);

  useEffect(() => {
    /**
     * We trigger this side effect to allow consumers to hook into the action state
     */
    setActionStateChanged({
      primary: primaryDisabled.current !== disablePrimary,
      secondary: secondaryDisabled.current !== disableSecondary,
    });

    primaryDisabled.current = disablePrimary;
    secondaryDisabled.current = disableSecondary;
  }, [disablePrimary, disableSecondary]);

  return (
    <ModalFooter
      primary={{
        label: showPrimary ? primaryLabel : '',
        trackingId: primaryTrackingId,
        onClick: isForm ? undefined : withClose(onClose, onPrimaryClick),
        disabled: disablePrimary,
        destructive,
        loading: primaryLoading,
        type: isForm ? 'submit' : 'button',
      }}
      secondary={{
        label: secondaryLabel,
        trackingId: secondaryTrackingId,
        onClick: onSecondaryClick ?? (() => true),
        disabled: disableSecondary,
        loading: secondaryLoading,
      }}
      tertiary={{
        label: backLabel,
        onClick: onBackClick,
        trackingId: backTrackingId,
        loading: tertiaryLoading,
      }}
      helperText={helperText}
    />
  );
};

type ButtonProps = {
  loading?: boolean;
  iconName?: IconName;
  label?: string;
  trackingId?: string;
  /**
   * Return `true` to close the modal automatically
   */
  onClick?: ClickHandler;
  disabled?: boolean;
  destructive?: boolean;
  autofocus?: boolean;
};

type ModalFooterProps = {
  primary?: ButtonProps & {
    type?: 'button' | 'submit';
  };
  secondary?: ButtonProps;
  // Allow node as label for backwards compatibility
  tertiary?: Omit<ButtonProps, 'label'> & { label?: ReactNode };
  helperText?: string;
  children?: ReactNode;
  className?: string;
};

export const ModalFooter = ({
  primary = {
    type: 'button',
  },
  secondary = {},
  tertiary = {},
  helperText,
  children,
  className,
}: ModalFooterProps) => {
  const { spacing } = useThemeValues();
  const { focusTimeout = 0, show, onClose } = useModalContext();

  const primaryButtonRef = useRef<HTMLButtonElement>(null);
  const secondaryButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        if (secondary.autofocus) {
          secondaryButtonRef.current?.focus();
        } else if (primary.autofocus) {
          primaryButtonRef.current?.focus();
        }
      }, focusTimeout + 100);
    }
  }, [show, focusTimeout, primary.autofocus, secondary.autofocus]);

  if (children) {
    return (
      <footer
        css={css`
          display: grid;
          gap: ${spacing(2)};
          align-items: center;
          grid-row-start: footer-start;
          grid-row-end: footer-end;
          padding: ${spacing(0, 3)};
        `}
        className={mergeClasses('wv-modal-footer', className)}
      >
        {children}
      </footer>
    );
  }

  return (
    <footer
      css={css`
        display: grid;

        // Tertiary only
        :has(.wv-modal-footer-tertiary-button):not(
            :has(.wv-modal-footer-helper-text, .wv-modal-footer-secondary-button, .wv-modal-footer-primary-button)
          ) {
          grid-template-areas: 'tertiary';
        }

        // Secondary only
        :has(.wv-modal-footer-secondary-button):not(
            :has(.wv-modal-footer-tertiary-button, .wv-modal-footer-helper-text, .wv-modal-footer-primary-button)
          ) {
          grid-template-areas: 'secondary';
          justify-content: end;
        }

        // Primary only
        :has(.wv-modal-footer-primary-button):not(
            :has(.wv-modal-footer-tertiary-button, .wv-modal-footer-helper-text, .wv-modal-footer-secondary-button)
          ) {
          grid-template-areas: 'primary';
          justify-content: end;
        }

        // Primary and tertiary
        :has(.wv-modal-footer-tertiary-button + .wv-modal-footer-primary-button):not(
            :has(.wv-modal-footer-helper-text, .wv-modal-footer-secondary-button)
          ) {
          grid-template-areas: 'tertiary secondary primary';
          grid-template-columns: auto max-content max-content;
        }

        // Primary and secondary
        :has(.wv-modal-footer-secondary-button + .wv-modal-footer-primary-button):not(
            :has(.wv-modal-footer-tertiary-button, .wv-modal-footer-helper-text)
          ) {
          grid-template-areas: 'secondary primary';
          grid-template-columns: max-content max-content;
          justify-content: end;
        }

        // Secondary and tertiary
        :has(.wv-modal-footer-tertiary-button + .wv-modal-footer-secondary-button):not(
            :has(.wv-modal-footer-helper-text, .wv-modal-footer-primary-button)
          ) {
          grid-template-areas: 'tertiary secondary';
          grid-template-columns: auto max-content;
        }

        // Primary, secondary and helper
        :has(.wv-modal-footer-helper-text + .wv-modal-footer-secondary-button + .wv-modal-footer-primary-button):not(
            :has(.wv-modal-footer-tertiary-button)
          ) {
          grid-template-areas: 'helper secondary primary';
          grid-template-columns: 1fr max-content max-content;
          justify-content: end;
        }

        // Primary and helper
        :has(.wv-modal-footer-helper-text + .wv-modal-footer-primary-button):not(
            :has(.wv-modal-footer-tertiary-button, .wv-modal-footer-secondary-button)
          ) {
          grid-template-areas: 'helper primary';
          grid-template-columns: 1fr max-content;
        }

        // Primary, secondary and tertiary
        :has(.wv-modal-footer-tertiary-button + .wv-modal-footer-secondary-button + .wv-modal-footer-primary-button) {
          grid-template-areas: 'tertiary helper secondary primary';
          grid-template-columns: auto 1fr max-content max-content;
        }

        // Primary, secondary, tertiary and helper
        :has(
            .wv-modal-footer-tertiary-button
              + .wv-modal-footer-helper-text
              + .wv-modal-footer-secondary-button
              + .wv-modal-footer-primary-button
          ) {
          grid-template-areas: 'tertiary helper secondary primary';
          grid-template-columns: auto 1fr max-content max-content;
        }

        gap: ${spacing(2)};
        align-items: center;
        grid-row-start: footer-start;
        grid-row-end: footer-end;
        padding: ${spacing(0, 3)};
      `}
      className={mergeClasses('wv-modal-footer', className)}
    >
      {/* This is for backwards compatibility - we should remove after moving modals away from Modal.Actions */}
      {tertiary.label && typeof tertiary.label !== 'string' && (
        <TextLink
          weight='bold'
          css={{ width: 'auto', gridArea: 'tertiary' }}
          onClick={withClose(onClose, tertiary.onClick)}
          trackingId={tertiary.trackingId}
          className='wv-modal-footer-tertiary-button'
        >
          {tertiary.label}
        </TextLink>
      )}
      {tertiary.label && typeof tertiary.label === 'string' && (
        <Button
          loading={tertiary.loading}
          iconName={tertiary.iconName}
          destructive={tertiary.destructive}
          trackingId={tertiary.trackingId}
          disabled={tertiary.disabled}
          css={{ gridArea: 'tertiary', width: 'fit-content' }}
          variant='tertiary'
          onClick={withClose(onClose, tertiary.onClick)}
          className='wv-modal-footer-tertiary-button'
        >
          {tertiary.label}
        </Button>
      )}

      {helperText && (
        <Text
          as='span'
          css={[
            {
              gridArea: 'helper',
              width: '100%',
              textAlign: 'end',
            },
            styles.truncate,
          ]}
          className='wv-modal-footer-helper-text'
        >
          {helperText}
        </Text>
      )}
      {secondary.label && (
        <Button
          loading={secondary.loading}
          iconName={secondary.iconName}
          destructive={secondary.destructive}
          trackingId={secondary.trackingId}
          disabled={secondary.disabled}
          css={{ gridArea: 'secondary', width: 'fit-content' }}
          variant='secondary'
          ref={secondaryButtonRef}
          /**
           * If there's a label, but no onClick, we assume the secondary button should close the modal
           */
          onClick={withClose(onClose, secondary.onClick ?? (() => true))}
          className='wv-modal-footer-secondary-button'
          showFocusOutline={secondary.autofocus}
        >
          {secondary.label}
        </Button>
      )}
      {primary.label && (
        <Button
          loading={primary.loading}
          iconName={primary.iconName}
          destructive={primary.destructive}
          trackingId={primary.trackingId}
          disabled={primary.disabled}
          type={primary.type}
          css={{ gridArea: 'primary', width: 'fit-content' }}
          onClick={withClose(onClose, primary.onClick)}
          className='wv-modal-footer-primary-button'
          ref={primaryButtonRef}
          showFocusOutline={primary.autofocus}
        >
          {primary.label}
        </Button>
      )}
    </footer>
  );
};
