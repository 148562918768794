import React, { useEffect, useMemo, useState } from 'react';
import { $generateHtmlFromNodes } from '@lexical/html';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';
import {
  ORDERED_LIST,
  UNORDERED_LIST,
  BOLD_STAR,
  BOLD_UNDERSCORE,
  BOLD_ITALIC_STAR,
  STRIKETHROUGH,
  ITALIC_UNDERSCORE,
  INLINE_CODE,
  type Transformer,
} from '@lexical/markdown';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import LexicalClickableLinkPlugin from '@lexical/react/LexicalClickableLinkPlugin';
import { LexicalComposer, type InitialEditorStateType, type InitialConfigType } from '@lexical/react/LexicalComposer';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { HorizontalRuleNode } from '@lexical/react/LexicalHorizontalRuleNode';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import {
  TextNode,
  ParagraphNode,
  type EditorState,
  type LexicalEditor,
  KEY_TAB_COMMAND,
  COMMAND_PRIORITY_HIGH,
} from 'lexical';
import { BeautifulMentionNode, ZeroWidthNode } from 'lexical-beautiful-mentions';
import { nanoid } from 'nanoid';
import { filter } from 'react-children-utilities';
import { editorStyles } from './common-styles';
import { DYNAMIC_FIELD_TRIGGER } from './constants';
import { DynamicFieldAttributes } from './molecules/dynamic-field-action';
import { DynamicFieldNode } from './nodes/dynamic-field-node';
import { ExtendedTextNode } from './nodes/extended-text-node';
import { ImageNode } from './nodes/image-node';
import {
  AutoLinkPlugin,
  ListMaxIndentLevelPlugin,
  LocalStoragePlugin,
  Toolbar,
  LinkPlugin,
  DraggableBlockPlugin,
  TabIndentationPlugin,
} from './plugins';
import { FloatingToolbarPlugin } from './plugins/floating-toolbar';
import { ToolbarStateProvider, EditorProvider, useEditorContext } from './providers';
import { defaultTheme } from './themes/default-theme';
import { parseHTML } from './utils';
import { $convertFromMarkdownStringWithDF } from './utils/markdown-transformers';

const Placeholder = ({ placeholder }: { placeholder?: string }) => {
  return <div className='editor-placeholder'>{placeholder ?? 'Enter text here...'}</div>;
};

const markdownTransformers: Transformer[] = [
  ORDERED_LIST,
  UNORDERED_LIST,
  BOLD_STAR,
  BOLD_UNDERSCORE,
  BOLD_ITALIC_STAR,
  STRIKETHROUGH,
  ITALIC_UNDERSCORE,
  INLINE_CODE,
];

const namespaceId = nanoid();

type RTEditorProps = {
  children: React.ReactNode;
  readOnly?: boolean;
  hasDraggableBlocks?: boolean;
  uniqueId?: string;
  saveToLocalStorage?: boolean;
  initialState?: InitialEditorStateType;
  initialHTML?: string;
  initialMarkdown?: string;
  onChange?: (editorState: EditorState, editor: LexicalEditor, tags: Set<string>) => void;
  onHTMLChange?: (parsedHTML: string) => void;
  dynamicFields?: DynamicFieldAttributes[];
  markdownContent?: string;
  customFloatingToolbar?: React.ReactNode;
  hideFloatingToolbar?: boolean;
  disableFocusTrap?: boolean;
  autoFocus?: boolean;
};

const EditorNodes = [
  ExtendedTextNode,
  { replace: TextNode, with: (node: TextNode) => new ExtendedTextNode(node.__text) },
  DynamicFieldNode,
  {
    replace: BeautifulMentionNode,
    with: (node: BeautifulMentionNode) => {
      return new DynamicFieldNode(node.getTrigger(), node.getValue(), node.getData());
    },
  },
  HeadingNode,
  ParagraphNode,
  QuoteNode,
  ListNode,
  ListItemNode,
  AutoLinkNode,
  LinkNode,
  ZeroWidthNode,
  DynamicFieldNode,
  HorizontalRuleNode,
  ImageNode,
];

export default function RTEditor({
  children,
  readOnly = false,
  hasDraggableBlocks = true,
  uniqueId,
  saveToLocalStorage,
  initialState,
  initialHTML,
  onChange,
  markdownContent,
  dynamicFields,
  onHTMLChange,
  customFloatingToolbar,
  hideFloatingToolbar = false,
  disableFocusTrap,
  autoFocus = true,
}: RTEditorProps) {
  const editor = filter(
    children,
    (child) =>
      React.isValidElement(child) &&
      typeof child.type !== 'string' &&
      'displayName' in child.type &&
      child.type.displayName === 'Editor'
  ) as React.ReactNode;

  const content = useMemo(() => {
    if (uniqueId && saveToLocalStorage) {
      return localStorage.getItem(uniqueId || '');
    }
    return;
  }, [uniqueId, saveToLocalStorage]);

  const editorConfig: InitialConfigType = {
    theme: defaultTheme,
    onError(error) {
      throw error;
    },
    editable: !readOnly,
    namespace: uniqueId ?? namespaceId,
    nodes: EditorNodes,
    editorState: initialHTML ? (editor) => parseHTML(editor, initialHTML) : initialState ?? content,
  };

  return (
    <div css={editorStyles({ isReadonly: readOnly })}>
      <LexicalComposer initialConfig={editorConfig}>
        <EditorProvider
          readOnly={readOnly}
          markdownContent={markdownContent}
          dynamicFields={dynamicFields}
          hasDraggableBlocks={hasDraggableBlocks}
          disableFocusTrap={disableFocusTrap}
        >
          <div className='editor-container'>
            {!readOnly ? (
              <ToolbarStateProvider>
                {children}
                <HistoryPlugin />
                {autoFocus && <AutoFocusPlugin />}
                <ListPlugin />
                {(onChange || onHTMLChange) && (
                  <OnChangePlugin
                    ignoreSelectionChange
                    ignoreHistoryMergeTagChange
                    onChange={(editorState, editor, tags) => {
                      if (onChange) {
                        onChange(editorState, editor, tags);
                      }
                      if (onHTMLChange) {
                        editor.update(() => {
                          onHTMLChange($generateHtmlFromNodes(editor, null));
                        });
                      }
                    }}
                  />
                )}

                <LinkPlugin />
                <AutoLinkPlugin />
                <LexicalClickableLinkPlugin />
                <ListMaxIndentLevelPlugin maxDepth={7} />
                <TabIndentationPlugin />
                <MarkdownShortcutPlugin transformers={markdownTransformers} />
                {!hideFloatingToolbar && <FloatingToolbarPlugin customFloatingToolbar={customFloatingToolbar} />}
                {uniqueId && saveToLocalStorage && <LocalStoragePlugin namespace={uniqueId} />}
              </ToolbarStateProvider>
            ) : (
              editor
            )}
          </div>
        </EditorProvider>
      </LexicalComposer>
    </div>
  );
}

type EditorProps = {
  placeholder?: string;
  trigger?: string;
};

export const Editor = ({ placeholder, trigger = DYNAMIC_FIELD_TRIGGER }: EditorProps) => {
  const [floatingAnchorElem, setFloatingAnchorElem] = useState<HTMLDivElement | null>(null);
  const { readOnly, markdownContent, dynamicFields, hasDraggableBlocks, disableFocusTrap } = useEditorContext();
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (readOnly) {
      editor.setEditable(false);
    }

    if (!readOnly) {
      editor.setEditable(true);
    }
  }, [readOnly]);

  useEffect(() => {
    if (markdownContent && dynamicFields) {
      editor.update(() => {
        $convertFromMarkdownStringWithDF(markdownContent, dynamicFields, trigger);
      });
    }
  }, [markdownContent, dynamicFields]);

  useEffect(() => {
    if (!disableFocusTrap) return;
    const removeListener = editor.registerCommand<KeyboardEvent>(KEY_TAB_COMMAND, () => true, COMMAND_PRIORITY_HIGH);

    return () => {
      removeListener();
    };
  }, [editor, disableFocusTrap]);

  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  return (
    <div className='editor-inner'>
      {floatingAnchorElem && !readOnly && hasDraggableBlocks && (
        <DraggableBlockPlugin anchorElem={floatingAnchorElem} />
      )}
      <RichTextPlugin
        contentEditable={
          <div className='editor-scroller'>
            <div className='editor' ref={onRef}>
              <ContentEditable className='editor-input' />
            </div>
          </div>
        }
        placeholder={placeholder !== '' ? <Placeholder placeholder={placeholder} /> : null}
        ErrorBoundary={LexicalErrorBoundary}
      />
    </div>
  );
};

Editor.displayName = 'Editor';

const RTEditorNamespace = Object.assign(RTEditor, {
  Editor,
  Toolbar,
});

export { RTEditorNamespace as RTEditor };
