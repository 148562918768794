import React from 'react';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import { classNames } from '@frontend/string';
import { useMultiStepContext } from '../../multi-step.context';
import { multiStepStyles } from '../../multi-step.styles';
import { MultiStepDisplay } from '../../multi-step.types';
import { MultiStepStepperItem, MultiStepStepperItemProps } from './stepper-item';

export type MultiStepStepperProps = {
  show?: boolean;
  className?: string;
  clickDisabled?: boolean;
  scrollDisabled?: boolean;
  display?: MultiStepDisplay;
  components?: Partial<{
    Item: (props: MultiStepStepperItemProps) => React.ReactNode;
  }>;
};
export function MultiStepStepper({
  className,
  scrollDisabled: scrollDisabledControl,
  clickDisabled: clickDisabledControl,
  display: displayControl,
  components = {},
}: MultiStepStepperProps) {
  const {
    parentSteps,
    activeParentStepIndex,
    stepper: { display: displayInternal, clickDisabled: clickDisabledInternal, scrollDisabled: scrollDisabledInternal },
  } = useMultiStepContext();

  /**
   * Allow direct prop control to override context state
   */
  const clickDisabled = typeof clickDisabledControl === 'boolean' ? clickDisabledControl : clickDisabledInternal;
  const scrollDisabled = typeof scrollDisabledControl === 'boolean' ? scrollDisabledControl : scrollDisabledInternal;
  const display = displayControl || displayInternal;

  const { Item = MultiStepStepperItem } = components;

  const virtuosoRef = React.useRef<VirtuosoHandle>(null);

  React.useEffect(() => {
    if (virtuosoRef.current) {
      virtuosoRef.current.scrollToIndex({
        index: activeParentStepIndex,
        behavior: 'smooth',
        align: 'center',
      });
    }
  }, [activeParentStepIndex]);

  if (!parentSteps.length) {
    return null;
  }

  const style = scrollDisabled ? { overflow: 'hidden' } : { overflow: 'auto' };
  return (
    <div css={multiStepStyles.stepperWrapper({ display })} className='multi-step__stepper'>
      <Virtuoso
        style={style}
        className={classNames('stepper', className)}
        css={multiStepStyles.stepper}
        itemContent={(idx, step) => (
          <Item key={step.id} display={display} clickDisabled={clickDisabled} step={step} index={idx} />
        )}
        data={parentSteps}
        ref={virtuosoRef}
      />
    </div>
  );
}
