import { ReactNode } from 'react';
import { css } from '@emotion/react';
import { NoAccessEmptyState as NoAccessEmptyStateSvg } from '@frontend/empty-states#svg';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';

type NoAccessEmptyStateProps = {
  message?: ReactNode;
};

/**
 * For pages with no acl access
 */
export const NoAccessEmptyState: React.FC<NoAccessEmptyStateProps> = ({ message }) => {
  return (
    <div css={containerStyle}>
      <div css={contentStyle}>
        <NoAccessEmptyStateSvg css={{ width: 375, height: 375 }} />
        {message && (
          <Text textAlign='center' css={{ color: theme.colors.neutral50, padding: theme.spacing(0, 1.5) }}>
            {message}
          </Text>
        )}
      </div>
    </div>
  );
};

const containerStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const contentStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: ${theme.spacing(4)};
`;
