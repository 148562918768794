import {
  CallPopTypes,
  GetWeavePopNotificationActionsByType,
  GetWeavePopNotificationByType,
  WeavePopNotification,
} from '@frontend/types';

declare global {
  interface Window {
    shell?: Adapter;
  }
}

type NotificationId = string;
type TransportEvents = { type: 'locations'; locations: string[] };
type LoggerEvents = { type: 'log' | 'error'; message: string };

type PopEvents =
  | { type: 'navigate'; index: number }
  | { type: 'dismiss'; id: string }
  | { type: 'action'; id: string; action: 'answer' | 'hangup' | 'dismiss' | 'viewed' }
  | { type: 'addNotification'; notification: CallPopTypes.Notification }
  | { type: 'removeNotification'; id: string }
  | { type: 'setActiveIndex'; index: number }
  | { type: 'setNotifications'; notifications: CallPopTypes.Notification[] }
  | { type: 'setOutlet'; outlet: 'profile' | 'softphone' | 'queue' };
export type PopActionPayload = Extract<PopEvents, { type: 'action' }>;
export type PopActionHandler = (e: IpcRendererEvent, data: PopActionPayload) => void;

export enum IPCEventName {
  PopAside = 'pop:aside',
  PopMain = 'pop:main',
  ShowNotification = 'notification:show',
  HideNotification = 'notification:hide',
  NotificationAction = 'notification:action',
}

// This is a naive version of the real electron.IpcRendererEvent. We probably won't every really need this
export type IpcRendererEvent = {
  preventDefault: () => void;
  ports: MessagePort[];
  sender: any; // IpcRenderer (from electron). Flesh this out if needed, but it probably won't be;
  senderFrame?: {
    url: string;
  };
};

export type IPCRendererCallback<Event extends keyof IPCEvents = keyof IPCEvents> = (
  e: IpcRendererEvent,
  data: IPCEvents[Event]
) => void;

/* ~~~~~~~~~~~~ IPC (provider types) ~~~~~~~~~~~~ */
export interface IpcShellContextType {
  status: IpcStatusType;
  loading: boolean;
  resetConnection: () => void;
}
export interface IpcShellProviderProps {
  context: IpcContext;
  children: React.ReactNode;
}
/* ~~~~~~~~~~~~ IPC (main process types) ~~~~~~~~~~~~ */

export type IpcEventNames = keyof IPCEvents;
export type IpcContext = 'main' | 'notifications';
export type BrowserWindowKeys = 'main' | 'alert'; // TODO*** this will be IpcContext when we update the window types and make them uniform
export type ConnectionStatus = 'connected' | 'disconnected';
export type FeatureAvailability = 'shell-theme' | 'recent-history';

export type IpcEventsList = {
  [K in keyof IPCEvents]: IpcEventInterceptor<K>;
};
export type IpcHandler<Event extends keyof IPCEvents> = (e: IpcRendererEvent, payload: IPCEvents[Event]) => void;
export interface IpcEventInterceptor<EventName extends keyof IPCEvents> {
  eventName: EventName;
  method: 'on' | 'handle';
  useMiddleware?: boolean;
  handler: IpcHandler<EventName>;
}

export interface IpcAppConnections {
  main: ConnectionStatus;
  notifications: ConnectionStatus;
}

export interface UpdateConnectionStatusPayload {
  context: IpcContext;
  status: ConnectionStatus;
}

export type SendIpcEvent = <EventName extends IpcEventNames>({
  eventName,
  key,
  payload,
}: {
  eventName: EventName;
  key: BrowserWindowKeys;
  payload: IPCEvents[EventName];
}) => Promise<{ success: boolean; eventName: IpcEventNames }>;

export type Send = <Event extends IpcEventNames>(e: Event, payload: IPCEvents[Event]) => void;

export type IpcStatusType = ConnectionStatus | 'waiting' | 'not_applicable' | 'unknown' | 'timeout';

export type IpcHealthCheckEventsPayload = {
  context: IpcContext;
  ipcStatus: IpcStatusType;
};

//note, keep these as keys as strings that match the above enum. Using the actual enum makes it harder to use this throughout the app
export type IPCEvents = {
  /* ipc <---> app(notifications | main) - ipc connection status for single app. */
  ['ipc:app:handshake']: Omit<IpcHealthCheckEventsPayload, 'ipcStatus'>;
  /* (ipc -> app(notifications | main)) - ipc connection status connected if both are connected otherwise disconnected */
  ['ipc:connection:sync']: IpcHealthCheckEventsPayload;
  /* Gets initial status of ipc connection */
  ['ipc:get:status:state']: { context: IpcContext };
  /* Restart ipc Event */
  ['ipc:connection:restart']: undefined;
  ['debug:enable-debug-mode']: undefined;
  ['debug:disable-debug-mode']: undefined;
  ['info:version']: string | undefined;
  ['info:update-available']: undefined | { version: string; urgency: 'low' | 'medium' | 'high' };
  ['window:open']: { url: string };
  ['window:control']: 'minimize' | 'maximize' | 'close';
  ['pop:main']: PopEvents | { type: 'inspect'; notification: CallPopTypes.Notification };
  ['pop:aside']: PopEvents;
  ['send:locations']: TransportEvents;
  ['handle:console:log']: LoggerEvents;
  ['update:new-version-available']: { version: string; urgency: 'low' | 'medium' | 'high' };
  ['notifications:empty']: undefined;
  ['notification:placement']: 'top-right' | 'bottom-right';
  ['notification:mode']: 'default' | 'experimental';
  ['notifications:hide']: undefined;
  ['notifications:resize']:
    | 'reset'
    | {
        width: number | undefined;
        height: number | undefined;
      };
  ['notification:hide']: NotificationId;
  ['notification:show']: {
    notification: WeavePopNotification;
  };
  ['notification:update']: {
    notification: WeavePopNotification;
  };
  ['window:history:back']: undefined;
  ['window:history:forward']: undefined;
  ['window:history:go']: { step: number };
  ['window:history:clear']: undefined;
  ['window:history:push']: { href: string; title: string };
  ['window:history:get']: undefined | { history: { href: string; title: string }[]; current: number };
  ['window:history:can-go-back']: undefined | boolean;
  ['window:history:can-go-forward']: undefined | boolean;
  ['window:online:status']: any;
  ['notification:action']: {
    [P in WeavePopNotification['type']]: {
      notification: GetWeavePopNotificationByType<P>;
      action: GetWeavePopNotificationActionsByType<P>;
    };
  }[WeavePopNotification['type']];
  focus: undefined;
  restart: undefined;
};

export type Adapter = {
  isShell: boolean;
  context: 'main' | 'alerts';
  featureAvailability: Set<FeatureAvailability>;
  isMac: boolean;
  isWindows: boolean;
  isLinux: boolean;
  version: string;
  flavor: string;
  on: <Event extends IpcEventNames, CB extends IPCRendererCallback<Event>>(e: Event, callback: CB, id?: string) => void;
  off: <Event extends IpcEventNames, CB extends IPCRendererCallback<Event>>(
    e: Event,
    callback: CB,
    id?: string
  ) => void;
  emit: <Event extends IpcEventNames, Payload extends IPCEvents[Event]>(e: Event, payload: Payload) => void;
  invoke: <Event extends IpcEventNames, Payload extends IPCEvents[Event]>(e: Event, payload: Payload) => Promise<any>;
  focus: () => void;
  restart: () => void;
};

export type ShellType = {
  [P in keyof Omit<Adapter, 'isShell'>]: Adapter[P] | undefined;
} & {
  isShell: boolean;
};

export const shell: ShellType = {
  isShell: !!window.shell,
  context: window.shell?.context,
  featureAvailability: window.shell?.featureAvailability,
  isMac: window.shell?.isMac,
  isWindows: window.shell?.isWindows,
  isLinux: window.shell?.isLinux,
  version: window.shell?.version,
  flavor: window.shell?.flavor,
  on: window.shell?.on,
  off: window.shell?.off,
  emit: window.shell?.emit,
  invoke: window.shell?.invoke,
  focus: window.shell?.focus,
  restart: window.shell?.restart,
};
