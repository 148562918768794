import { ElementType, forwardRef, ReactNode } from 'react';
import { css } from '@emotion/react';
import composeRefs from '@seznam/compose-react-refs';
import { useContentOverflow } from '../../hooks';
import { PolymorphicComponentPropWithRef, PolymorphicRef } from '../../type-utils';
import { Text } from '../text';
import { useTooltip } from '../tooltip';

type TruncatedProps = {
  tooltipOptions?: Parameters<typeof useTooltip>['0'];
  TooltipComponent?: ReactNode;
};

type PolymorphicTruncatedProps<C extends ElementType = 'p'> = PolymorphicComponentPropWithRef<C, TruncatedProps>;

type TruncatedComponent = <C extends ElementType = 'p'>(props: PolymorphicTruncatedProps<C>) => ReactNode;

/**
 * This component truncates text and shows a tooltip with the full text when the text is truncated.
 * You can pass the "as" prop to render the text as a different element.
 */
export const Truncated: TruncatedComponent = forwardRef(
  <T extends ElementType = 'p'>(
    { as, children, tooltipOptions, TooltipComponent, ...rest }: PolymorphicTruncatedProps<T>,
    ref: PolymorphicRef<T>
  ) => {
    const Component = as || 'p';
    const {
      tooltipProps,
      triggerProps: tooltipTriggerProps,
      Tooltip,
    } = useTooltip(tooltipOptions ? tooltipOptions : { placement: 'top-start' });
    const [isTruncatedRef, isTruncated] = useContentOverflow();

    const combinedRefs = composeRefs(ref, tooltipTriggerProps.ref, isTruncatedRef);

    return (
      <>
        <Component
          css={css`
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          `}
          ref={combinedRefs}
          {...rest}
        >
          {children}
        </Component>

        {isTruncated && (
          <Tooltip {...tooltipProps}>
            {TooltipComponent ? (
              TooltipComponent
            ) : (
              <Text size='medium' color='white'>
                {children}
              </Text>
            )}
          </Tooltip>
        )}
      </>
    );
  }
);
