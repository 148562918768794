import { Children } from 'react';
import { ComponentProps } from '@frontend/types';
import { ModalActions, ModalFooter } from './atoms/modal-actions';
import { ModalFlexContent } from './atoms/modal-flex-content';
import { ModalHeader, ModalLabel } from './atoms/modal-header';
import { CenteredModal } from './molecules/centered-modal';

export type ModalProps = Omit<ComponentProps<typeof CenteredModal>, 'flex'>;

const ModalComponent = ({ children, minWidth = 0, maxWidth = 400, ...rest }: ModalProps) => {
  if (rest.asChild && Children.count(children) > 1) {
    throw new Error('Only one child allowed when using `asChild`');
  }

  return (
    <CenteredModal flex minWidth={minWidth} maxWidth={maxWidth} {...rest}>
      {children}
    </CenteredModal>
  );
};

export const Modal = Object.assign(ModalComponent, {
  /**
   * @deprecated Use Modal.Footer instead
   *
   * Component for adding secondary + primary actions to a modal
   * @param {string} [props.className] Optional css/classname
   * @param {string | function} props.onPrimaryClick Either 'submit' (for buttons controlling a form in a modal) or a click handler function.
   * @param {function} [props.onSecondaryClick] Optionals click handler for the secondary action. Defaults to the modals onClose function.
   * @param {string} [props.primaryLabel] Optional text for the primary button (default = 'Save')
   * @param {string} [props.secondaryLabel] Optional text for the secondary button (default = 'Cancel')
   */
  Actions: ModalActions,
  Footer: ModalFooter,
  Header: ModalHeader,
  Label: ModalLabel,
  Body: ModalFlexContent,
});
