import { lazy, Suspense } from 'react';
import { LazyAssetsFallback, type LazyAssetProps } from '@frontend/internal-assets';

const LazyBulkTexting = lazy(() => import('./svg-components/bulk-texting'));

const BulkTexting = ({ assetsFallbackProps = {}, customFallback, ...rest }: LazyAssetProps) => (
  <Suspense fallback={customFallback || <LazyAssetsFallback {...assetsFallbackProps} />}>
    <LazyBulkTexting {...rest} />
  </Suspense>
);

export default BulkTexting;
