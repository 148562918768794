import { ReactNode, createContext, useContext, useRef } from 'react';
import { createStore, useStore as useStoreZustand } from 'zustand';
import { PersistOptions, subscribeWithSelector, persist } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';
import { StateCreator, StoreMutatorIdentifier } from 'zustand/vanilla';
import { pick } from './utils/pick';

export const createProvider =
  <T,>() =>
  <Mos extends [StoreMutatorIdentifier, unknown][] = []>(initializer: StateCreator<T, [], Mos>) => {
    type TInitialState = Partial<T>;
    type TInitState = (prevStore: ReturnType<typeof initializer>) => TInitialState;
    const initializeStore = (initialState: TInitState | TInitialState) =>
      createStore<T>()(
        subscribeWithSelector((...args) => {
          const initStore = initializer(...args);
          const initState: TInitialState = typeof initialState === 'function' ? initialState(initStore) : initialState;
          return {
            ...initStore,
            ...initState,
          };
        })
      );

    type StoreType = ReturnType<typeof initializeStore>;

    const ZustandContext = createContext<StoreType | null>(null);

    const StoreProvider = ({
      children,
      initialState,
    }: {
      children: ReactNode;
      initialState?: TInitState | TInitialState;
    }) => {
      const storeRef = useRef<StoreType>();
      if (!storeRef.current) {
        storeRef.current = initializeStore(initialState ?? {});
      }
      return <ZustandContext.Provider value={storeRef.current}>{children}</ZustandContext.Provider>;
    };

    function useStore<SelectedData extends (keyof T)[]>(selector: SelectedData): Pick<T, SelectedData[number]>;
    function useStore<SelectedData extends (state: T) => any>(selector: SelectedData): ReturnType<SelectedData>;

    function useStore<SelectedData>(
      selector: (state: T) => SelectedData,
      compareFunction?: (a: SelectedData, b: SelectedData) => boolean
    ) {
      const store = useContext(ZustandContext);

      if (!store) {
        throw new Error('useStore must be used within a StoreProvider');
      }

      if (Array.isArray(selector)) {
        const stateKeys = selector;
        const selectFromState = (state: T) => pick(state, ...stateKeys) as unknown as SelectedData;
        // Disabling rules of hooks for this overload function. WARN: if we can find a better way to do this, that would probably be safer
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return useStoreZustand(store, selectFromState, shallow);
      }

      // Disabling rules of hooks for this overload function. WARN: if we can find a better way to do this, that would probably be safer
      // eslint-disable-next-line react-hooks/rules-of-hooks
      return useStoreZustand(store, selector, compareFunction);
    }

    function useNonReactiveStore() {
      const store = useContext(ZustandContext);

      if (!store) {
        throw new Error('useStore must be used within a StoreProvider');
      }

      return store;
    }

    return { Provider: StoreProvider, useStore, useNonReactiveStore };
  };

export const createPersistProvider =
  <T,>() =>
  <Mos extends [StoreMutatorIdentifier, unknown][] = []>(initializer: StateCreator<T, [], Mos>) => {
    type TInitialState = Partial<T>;
    type TInitState = (prevStore: ReturnType<typeof initializer>) => TInitialState;

    const initializeStore = (initialState: TInitState | TInitialState, persistOptions: PersistOptions<T>) => {
      return createStore<T>()(
        subscribeWithSelector(
          persist((...args) => {
            const initStore = initializer(...args);
            const initState: TInitialState =
              typeof initialState === 'function' ? initialState(initStore) : initialState;
            return {
              ...initStore,
              ...initState,
            };
          }, persistOptions)
        )
      );
    };

    type StoreType = ReturnType<typeof initializeStore>;

    const ZustandContext = createContext<StoreType | null>(null);

    const StoreProvider = ({
      children,
      initialState,
      persistOptions,
    }: {
      children: ReactNode;
      initialState?: TInitState | TInitialState;
      persistOptions: PersistOptions<T>;
    }) => {
      const storeRef = useRef<StoreType>();
      if (!storeRef.current) {
        storeRef.current = initializeStore(initialState ?? {}, persistOptions);
      }
      return <ZustandContext.Provider value={storeRef.current}>{children}</ZustandContext.Provider>;
    };

    function useStore<SelectedData extends (keyof T)[]>(selector: SelectedData): Pick<T, SelectedData[number]>;
    function useStore<SelectedData extends (state: T) => any>(selector: SelectedData): ReturnType<SelectedData>;

    function useStore<SelectedData>(
      selector: (state: T) => SelectedData,
      compareFunction?: (a: SelectedData, b: SelectedData) => boolean
    ) {
      const store = useContext(ZustandContext);

      if (!store) {
        throw new Error('useStore must be used within a StoreProvider');
      }

      if (Array.isArray(selector)) {
        const stateKeys = selector;
        const selectFromState = (state: T) => pick(state, ...stateKeys) as unknown as SelectedData;
        // Disabling rules of hooks for this overload function. WARN: if we can find a better way to do this, that would probably be safer
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return useStoreZustand(store, selectFromState, shallow);
      }

      // Disabling rules of hooks for this overload function. WARN: if we can find a better way to do this, that would probably be safer
      // eslint-disable-next-line react-hooks/rules-of-hooks
      return useStoreZustand(store, selector, compareFunction);
    }

    function useNonReactiveStore() {
      const store = useContext(ZustandContext);

      if (!store) {
        throw new Error('useStore must be used within a StoreProvider');
      }

      return store;
    }

    return { Provider: StoreProvider, useStore, useNonReactiveStore };
  };
