import React from 'react';
import { isEqual, merge, pick } from 'lodash-es';
import { MultiStepContext } from './multi-step.context';
import { MultiStepControl } from './multi-step.hooks';
import { StepData } from './multi-step.types';

export type MultiStepProviderProps = React.PropsWithChildren<Omit<MultiStepControl, 'controlRef'>>;

export type MultiStepProviderRef = MultiStepControl;

const MultiStepProvider = React.forwardRef<MultiStepProviderRef, MultiStepProviderProps>(
  ({ children, setSteps, activeStep, steps, setActiveStep, ...props }, ref) => {
    /**
     * Set step data from children
     */
    const prevSteps = React.useRef<Pick<StepData, 'id' | 'label'>[]>([]);

    React.useEffect(() => {
      React.Children.forEach(children, (child) => {
        if (React.isValidElement(child)) {
          const displayName =
            typeof child.type !== 'string' && 'displayName' in child.type
              ? String(child.type.displayName).toLowerCase()
              : false;

          if (displayName && displayName.includes('body')) {
            const stepChildren = React.Children.toArray(child.props.children);
            const mapped = stepChildren
              .reduce((acc, stepChild) => {
                const found = findStepChild(stepChild) as React.ReactElement<unknown> | false;

                if (found) {
                  acc.push(
                    merge(
                      {
                        isValid: true,
                        isComplete: false,
                        parentId: undefined,
                        childSteps: [] as string[],
                      },
                      pick(found.props, ['id', 'label', 'isValid', 'isComplete', 'parentId'])
                    ) as StepData
                  );
                }

                return acc;
              }, [] as StepData[])
              .sort((a, b) => {
                if (a.parentId && !b.parentId) {
                  return 1;
                }
                if (!a.parentId && b.parentId) {
                  return -1;
                }
                return 0;
              });

            const sorted = mapped.reduce((acc, step) => {
              const parent = step.parentId ? acc.find((a) => a.id === step.parentId) : null;
              if (parent) {
                const parentIndex = acc.indexOf(parent);

                let insertIndex = parentIndex + 1;

                if (parentIndex === -1) {
                  console.warn('Parent step not found for child step', step);
                } else {
                  if (!acc[parentIndex].childSteps.includes(step.id)) {
                    acc[parentIndex].childSteps.push(step.id);
                  }

                  for (let i = parentIndex + 1; i < acc.length; i++) {
                    if (acc[i]?.parentId !== parent.id) {
                      insertIndex = i;
                      break;
                    }
                  }

                  acc.splice(insertIndex, 0, step);
                }
              } else {
                acc.push(step);
              }

              return acc;
            }, [] as StepData[]);

            if (sorted.length) {
              setSteps((prev) => {
                const checkSteps = sorted.map(({ id, label }) => ({ id, label }));

                if (isEqual(checkSteps, prevSteps.current)) {
                  return prev;
                }

                prevSteps.current = checkSteps;

                return sorted;
              });
            }
          }
        }
      });
    }, [children]);

    /**
     * Set the initial active step
     */
    React.useEffect(() => {
      if (activeStep || !steps.length) {
        return;
      }

      setActiveStep(steps[0].id);
    }, [activeStep, steps]);

    const controls = React.useMemo(() => {
      return {
        setSteps,
        activeStep,
        steps,
        setActiveStep,
        ...props,
      };
    }, [
      activeStep,
      steps,
      props.isInModal,
      props.showCancelAction,
      props.stepper.show,
      props.stepper.display,
      props.stepper.clickDisabled,
      props.stepper.scrollDisabled,
    ]);

    React.useImperativeHandle(ref, () => controls, [controls]);

    return <MultiStepContext.Provider value={controls}>{children}</MultiStepContext.Provider>;
  }
); // end forwardRef

MultiStepProvider.displayName = 'MultiStepProvider';

export default MultiStepProvider;

function findStepChild<T extends React.ReactNode>(stepChild: T): T | false {
  if (React.isValidElement(stepChild)) {
    const stepDisplayName =
      typeof stepChild.type !== 'string' && 'displayName' in stepChild.type
        ? String(stepChild.type.displayName).toLowerCase()
        : false;

    /**
     * Has step in the name
     */
    if (stepDisplayName && stepDisplayName.includes('step')) {
      return stepChild;
    }

    if (stepChild.props.id && stepChild.props.label) {
      return stepChild;
    }

    if (stepChild.props && stepChild.props.children) {
      const found = (React.Children.toArray(stepChild.props.children).find(findStepChild) as T) || false;
      if (found) {
        return found;
      }
    }
  }
  return false;
}
