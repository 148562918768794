import { createContext, useEffect, useState, useRef, useContext, useCallback } from 'react';
import { IPCRendererCallback, IpcStatusType, IpcShellContextType, IpcShellProviderProps, shell } from './adapter';

const syncEventSlug = 'ipc:connection:sync';
const handshakeEventSlug = 'ipc:app:handshake';

/** Create Context with Initial State */
const ipcShellContext = createContext<IpcShellContextType>({
  status: 'waiting',
  resetConnection: () => {},
  loading: false,
});

export const IpcShellProvider = ({ context, children }: IpcShellProviderProps) => {
  const [status, setStatus] = useState<IpcStatusType>('waiting');
  const [loading, setLoading] = useState<boolean>(false);
  useShellVersionCheck(); // Check Version & Check for updates

  const initialLoad = useRef<boolean>(true);

  const handleIpcConnectionSync: IPCRendererCallback<'ipc:connection:sync'> = useCallback((_e, { ipcStatus }) => {
    setStatus((prevStatus) => (prevStatus !== ipcStatus ? ipcStatus : prevStatus));
  }, []);

  const handleIpcAppHandshake: IPCRendererCallback<'ipc:app:handshake'> = useCallback(() => {
    shell.emit?.(handshakeEventSlug, { context });
  }, [context]);

  const fetchInitialStatus = useCallback(() => {
    if (initialLoad.current) {
      initialLoad.current = false;
      shell
        .invoke?.('ipc:get:status:state', { context })
        .then((incomingStatus) => {
          if (incomingStatus) {
            setStatus((prevStatus) => {
              if (prevStatus !== incomingStatus) {
                shell.emit?.(handshakeEventSlug, { context, ipcStatus: incomingStatus });
                return incomingStatus;
              }
              return prevStatus;
            });
          }
        })
        .catch((error) => console.error('[IPC Provider] Failed to get initial state:', error));
    }
  }, [context]);

  useEffect(() => {
    if (!shell.isShell) {
      if (status !== 'not_applicable') setStatus('not_applicable');
      return;
    }
    fetchInitialStatus();

    const syncStatusID = `${syncEventSlug}:${context}`;
    const handshakeID = `${handshakeEventSlug}:${context}`;

    shell.on?.(syncEventSlug, handleIpcConnectionSync, syncStatusID);
    shell.on?.(handshakeEventSlug, handleIpcAppHandshake, handshakeID);

    return () => {
      shell.off?.(syncEventSlug, handleIpcConnectionSync, syncStatusID);
      shell.off?.(handshakeEventSlug, handleIpcAppHandshake, handshakeID);
    };
  }, [handleIpcAppHandshake, handleIpcConnectionSync, fetchInitialStatus]);

  /* Reset IPC Connection & Reload The Window*/
  const resetConnection = async () => {
    if (!shell.isShell) return;
    setLoading(true);
    setStatus('waiting');
    try {
      const res = await shell.invoke?.('ipc:connection:restart', undefined);
      console.log('IPC Connection Restarted:', res);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Failed to restart IPC Connection:', error);
    }
  };

  return <ipcShellContext.Provider value={{ status, loading, resetConnection }}>{children}</ipcShellContext.Provider>;
};

export const useIpcShellProvider = () => useContext(ipcShellContext);

let lastRan: Date | undefined = undefined;
const throttle = 2000;

const useShellVersionCheck = () => {
  const versionRef = useRef<string | undefined>(undefined);

  /* version check */
  useEffect(() => {
    if (!shell.isShell || (lastRan && lastRan.valueOf() > Date.now() - throttle)) return;

    lastRan = new Date();
    const id = `info:version:${Math.random()}`;
    /* Initial check */
    shell.emit?.('info:version', undefined);

    /* interval checks */
    const versionCheckInterval = setInterval(() => {
      shell.emit?.('info:version', undefined);
    }, 45000);

    const handler: IPCRendererCallback<'info:version'> = (_e, version) => {
      if (version && version !== versionRef.current) {
        shell.version = version;
        versionRef.current = version;
      }
    };

    shell.on?.('info:version', handler, id);
    return () => {
      clearInterval(versionCheckInterval);
      shell.off?.('info:version', handler, id);
    };
  }, [shell]);
};
