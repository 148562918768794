/*
  The motivation behind creating a wrapper around react-i18next is so that we can easily replace core functionality
  For example, if we decided to drop react-i18next and use another tool,
  all the changes could be isolated to this package rather than all the packages that use it.
*/
import { useMemo } from 'react';
import i18next, { InitOptions } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
// This will change when we use a backend to serve up translations
import locales from '@frontend/locales';
import type { i18nEvents } from './types';

export type AvailableLanguages = 'en' | 'fr' | 'es';
export const supportedLanguages: Record<AvailableLanguages, string> = {
  en: 'English',
  fr: 'French',
  es: 'Spanish',
};

export const defaultNS = 'base';

const defaultConfig: InitOptions = {
  debug: false,
  detection: {
    order: ['localStorage', 'htmlTag'],
    caches: ['localStorage'],
  },
  defaultNS,
  fallbackLng: 'en',
  resources: locales,
  ns: ['base'],
  supportedLngs: Object.keys(supportedLanguages),
  keySeparator: '>>',
  nsSeparator: '::',
  interpolation: { escapeValue: false },
};

i18next.use(LanguageDetector).use(initReactI18next).init(defaultConfig);

export const events = {
  languageChanged: (eventHandler: i18nEvents['languageChanged']) => i18next.on('languageChanged', eventHandler),
  initialized: (eventHandler: i18nEvents['initialized']) => i18next.on('initialized', eventHandler),
  missingKey: (eventHandler: i18nEvents['missingKey']) => i18next.on('missingKey', eventHandler),
  failedLoading: (eventHandler: i18nEvents['failedLoading']) => i18next.on('failedLoading', eventHandler),
};

export const currentLanguage = i18next.language;
export const getCurrentLanguage = () => {
  return i18next.language;
};
export const useCurrentLanguage = () => {
  const lang = useMemo(() => i18next.language, [i18next.language]);
  return lang;
};

export { i18next };
export { useTranslation, Trans } from 'react-i18next';
export type { TFunction } from 'i18next';
export { Interpolation } from './interpolation';
