import { ElementType, ReactElement, forwardRef, useState, KeyboardEvent, useRef, useEffect, ReactNode } from 'react';
import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import { KeyNames } from '../../constants';
import { createSplitSpecificChildren } from '../../helpers';
import { contextFactory } from '../../hooks';
import { MoreIcon, WarningBadgeIcon } from '../../icon';
import { PolymorphicComponentPropWithRef, PolymorphicRef } from '../../type-utils';
import { useStyles } from '../../use-styles';
import { ContextMenuActions } from '../context-menu';
import { IconButton } from '../icon-button';
import { PopoverMenu, PopoverMenuItem, usePopoverMenu } from '../popover';
import { Text } from '../text';
import { Bubble, BubbleProps } from './atoms/bubble';
import { ImageBubble } from './atoms/image-bubble';
import { ChatItemTag, chatItemTagDisplayName } from './atoms/tag';
import { Direction } from './chat-item-types';
import { formatTimestamp } from './utils';

dayjs.extend(calendar);

export type ChatItemProps = {
  direction: Direction;
  avatar: ReactElement;
  timestamp: string | Date;
  senderName?: string;
  error?: string;
  statusComponent?: ReactElement;
  hideTimestamp?: boolean;
  isFuture?: boolean;
  isPaused?: boolean;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
  maxWidth?: string;
  isSending?: boolean;
  moreActions?: ContextMenuActions;
  moreActionsTrackingId?: string;
  tagsEndAdornment?: ReactNode;
  tagsStartAdornment?: ReactNode;
} & Omit<Partial<BubbleProps>, 'direction'>;

type PolymorphicChatItemProps<C extends ElementType = 'ul'> = PolymorphicComponentPropWithRef<C, ChatItemProps>;

type ChatItemContextType = {
  direction: Direction;
  isHovering: boolean;
  isFocused: boolean;
  isFuture: boolean;
  isPaused: boolean;
  isSending: boolean;
  hasError: boolean;
  hoverProps: {
    onMouseOver: () => void;
    onMouseOut: () => void;
  };
};

const RESIZE_LISTENER_KEY = 'chat-item-resize-listener';

export const [ChatItemContext, useChatItemContext] = contextFactory<ChatItemContextType>(
  'useChatItemContext must be used inside a ChatItemContext Provider.'
);

type ChatItemComponent = <C extends ElementType = 'ul'>(props: PolymorphicChatItemProps<C>) => ReactNode;

// eslint-disable-next-line react/display-name
export const ChatItemOptional: ChatItemComponent = forwardRef(
  <C extends ElementType = 'ul'>(
    {
      as,
      direction,
      avatar,
      timestamp,
      senderName,
      error,
      statusComponent: StatusComponent,
      hideTimestamp,
      onMouseOver,
      onMouseOut,
      isFuture: isFutureProp,
      isPaused,
      children,
      text,
      subText,
      bubbleIcon,
      backgroundColor,
      onClick,
      maxWidth,
      isSending,
      moreActions,
      moreActionsTrackingId,
      textColor,
      tagsStartAdornment,
      tagsEndAdornment,
      ...rest
    }: PolymorphicChatItemProps<C>,
    ref: PolymorphicRef<C>
  ) => {
    const Component = as || 'ul';
    const [isHovering, setIsHovering] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const tagsRowRef = useRef<HTMLDivElement>(null);
    const bubblesContainerRef = useRef<HTMLDivElement>(null);
    const [tagsRowMaxWidth, setTagsRowMaxWidth] = useState(bubblesContainerRef.current?.clientWidth ?? 0);
    const [tagsRowHeight, setTagsRowHeight] = useState(tagsRowRef.current?.clientHeight ?? 0);
    const isFuture =
      isFutureProp ??
      (direction === 'outbound' && typeof timestamp !== 'string' && timestamp.getTime() > new Date().getTime());
    const timestampText = typeof timestamp === 'string' ? timestamp : formatTimestamp(timestamp, isFuture);
    const splitTagChildren = createSplitSpecificChildren<typeof chatItemTagDisplayName>(children, true);
    const [tagChildren, otherChildren] = splitTagChildren(chatItemTagDisplayName);
    const hasTags = tagChildren.length > 0 || !!tagsStartAdornment || !!tagsEndAdornment;
    const containerStyles = useStyles('ChatItem', 'container', {
      direction,
      showSenderName: isHovering || isFocused,
      showTimestamp: isHovering || isFocused || isFuture || !!isSending,
      isFuture,
      isPaused: !!isPaused,
      maxWidth: maxWidth ?? '100%',
      tagsRowMaxWidth,
      tagsRowHeight,
      hasTags,
    });
    const { getMenuProps, getTriggerProps, getItemProps } = usePopoverMenu({
      placement: direction === 'outbound' ? 'left-start' : 'right-start',
    });

    const handleMouseOver = () => {
      setIsHovering(true);
      onMouseOver?.();
    };
    const handleMouseOut = () => {
      setIsHovering(false);
      onMouseOut?.();
    };
    const hoverProps = {
      onMouseOver: handleMouseOver,
      onMouseOut: handleMouseOut,
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLElement>) => {
      if (event.key === KeyNames.Enter || event.key === KeyNames.Space) {
        onClick?.(event);
      }
    };

    const ResolvedStatusComponent = !!error
      ? StatusComponent || <DefaultErrorStatusComponent errorText={error} />
      : StatusComponent;
    const resolvedHideTimestamp = hideTimestamp ?? !!error;

    const handleResize = () => {
      setTagsRowMaxWidth(bubblesContainerRef.current?.clientWidth ?? 0);
    };

    useEffect(() => {
      window.addEventListener(RESIZE_LISTENER_KEY, handleResize);
      return () => window.removeEventListener(RESIZE_LISTENER_KEY, handleResize);
    }, []);

    useEffect(() => {
      handleResize();
    }, [bubblesContainerRef.current?.clientWidth, maxWidth]);

    useEffect(() => {
      setTagsRowHeight(tagsRowRef.current?.clientHeight ?? 0);
      setIsHovering(false);
      setIsFocused(false);
    }, [tagChildren.length, tagsRowRef.current?.clientHeight]);

    useEffect(() => {
      setIsHovering(false);
      setIsFocused(false);
    }, [JSON.stringify(moreActions)]);

    return (
      <>
        <ChatItemContext.Provider
          value={{
            direction,
            isHovering,
            isFocused,
            isFuture,
            isPaused: !!isPaused,
            isSending: !!isSending,
            hasError: !!error,
            hoverProps,
          }}
        >
          <Component
            css={containerStyles}
            ref={ref}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            aria-label={`Message from ${senderName}, sent ${timestampText}`}
            {...rest}
          >
            <div className='avatar-container'>{avatar}</div>
            <div className='content-container'>
              <div ref={bubblesContainerRef} className='bubbles-container'>
                {otherChildren}
                {(!!text || !!subText) && (
                  <Bubble
                    text={text}
                    subText={subText}
                    bubbleIcon={bubbleIcon}
                    backgroundColor={backgroundColor}
                    onClick={onClick}
                    onKeyDown={handleKeyDown}
                    maxWidth={maxWidth}
                    textColor={textColor}
                  />
                )}
              </div>
              <div className='tag-transition-container'>
                {hasTags && (
                  <div ref={tagsRowRef} className='tags-container'>
                    {tagsStartAdornment}
                    {tagChildren}
                    {tagsEndAdornment}
                  </div>
                )}
              </div>
              <span className='metadata-container' {...hoverProps}>
                {!!moreActions?.length && (
                  <IconButton
                    className='more-actions-button'
                    size='xsmall'
                    {...getTriggerProps()}
                    label='More Actions'
                    trackingId={moreActionsTrackingId}
                  >
                    <MoreIcon className='more-actions-icon' />
                  </IconButton>
                )}
                {senderName && (
                  <Text size='small' className='sender-name' color='light'>
                    {senderName}
                  </Text>
                )}
                {isSending ? (
                  <Text className='timestamp' color='light' size='small'>
                    Sending...
                  </Text>
                ) : (
                  !resolvedHideTimestamp && (
                    <Text className='timestamp' as='time' size='small'>
                      {timestampText}
                    </Text>
                  )
                )}
                {!!ResolvedStatusComponent && <span className='status-container'>{ResolvedStatusComponent}</span>}
              </span>
            </div>
          </Component>
        </ChatItemContext.Provider>
        {moreActions?.length && (
          <PopoverMenu {...getMenuProps()} returnFocus={false}>
            {moreActions.map(({ label, onClick, ...action }, index) => (
              <PopoverMenuItem
                key={`chat-item-more-action-${action.trackingId ?? index}`}
                {...getItemProps({
                  index,
                  onClick,
                })}
                {...action}
              >
                {label}
              </PopoverMenuItem>
            ))}
          </PopoverMenu>
        )}
      </>
    );
  }
);

const DefaultErrorStatusComponent = ({ errorText }: { errorText: string }) => {
  return (
    <>
      <Text color='error' size='small'>
        {errorText}
      </Text>
      <WarningBadgeIcon size={16} color='error' />
    </>
  );
};

const ChatItemNamespace = Object.assign(ChatItemOptional, {
  Bubble: Bubble,
  Image: ImageBubble,
  Tag: ChatItemTag,
});

export { ChatItemNamespace as ChatItem };
