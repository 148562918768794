import { css } from '@emotion/react';
import { motion, type MotionProps } from 'motion/react';
import type { CommonHTMLAttributes } from '@frontend/types';
import { WeaveThemeValues } from '../../../component-theme/theme-types';
import { mergeClasses } from '../../../helpers';
import { styles } from '../../../styles';
import { useStyles } from '../../../use-styles';
import { animated } from '../../animated';
import { useModalContext } from '../provider';

type ModalBackdropProps = {
  zIndex?: WeaveThemeValues['ZIndex'];
  omitOffset?: boolean;
  showBackdrop?: boolean;
};

export const ModalBackdrop = ({
  className,
  onClick,
  zIndex,
  omitOffset,
  ...rest
}: CommonHTMLAttributes & ModalBackdropProps) => {
  const { loadingState } = useModalContext();
  const backdropStyles = useStyles('Modal', 'modalBackdropStyles');
  return (
    <animated.div
      css={[backdropStyles, zIndex && { zIndex }, !omitOffset && ((theme) => styles.getAdjustedHeight(theme))]}
      className={mergeClasses('wv-modal-backdrop', className)}
      {...rest}
      // prevent click to close while in a loading state
      onClick={
        loadingState?.show
          ? undefined
          : (e) => {
              e.stopPropagation();
              onClick?.(e);
            }
      }
    />
  );
};

export const TrayModalBackdrop = ({
  className,
  onClick,
  zIndex,
  omitOffset,
  showBackdrop = true,
  ...rest
}: CommonHTMLAttributes & MotionProps & ModalBackdropProps) => {
  const { loadingState } = useModalContext();
  const backdropStyles = useStyles('Modal', 'modalBackdropStyles');
  return (
    <motion.div
      css={[
        showBackdrop && backdropStyles,
        zIndex && { zIndex },
        !omitOffset && ((theme) => styles.getAdjustedHeight(theme)),
        !showBackdrop &&
          css`
            pointer-events: none;
          `, // Allows the background to be interactive
      ]}
      className={mergeClasses('wv-modal-backdrop', className)}
      {...rest}
      // prevent click to close while in a loading state
      onClick={
        loadingState?.show
          ? undefined
          : (e) => {
              e.stopPropagation();
              onClick?.(e);
            }
      }
    />
  );
};
