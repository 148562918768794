import type { ReactNode } from 'react';
import type { CommonHTMLLIAttributes } from '@frontend/types';
import { CheckIcon } from '../../icon';
import { useStyles } from '../../use-styles';
import type { ListboxOptionGetterProps } from './use-listbox';

type ListboxLiProps = CommonHTMLLIAttributes &
  ListboxOptionGetterProps & {
    children: ReactNode;
    trackingId?: string;
  };

export const ListboxLi = ({ children, trackingId, ...rest }: ListboxLiProps) => {
  const liStyles = useStyles('ListboxLi', 'liStyles', rest);
  const iconStyles = useStyles('ListboxLi', 'iconStyles', rest);

  return (
    <li css={liStyles} {...rest} data-trackingid={trackingId}>
      <span css={{ cursor: 'default' }}>{children}</span>
      <CheckIcon color='primary' css={iconStyles} />
    </li>
  );
};
