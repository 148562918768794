import type { HTMLAttributes } from 'react';
import { css } from '@emotion/react';
import type { CommonHTMLAttributes } from '@frontend/types';
import { WeaveThemes } from '../../../component-theme';
import { mergeClasses } from '../../../helpers';
import { useThemeValues } from '../../../hooks/use-theme-values';
import { styles } from '../../../styles';

type Props = CommonHTMLAttributes &
  Pick<HTMLAttributes<HTMLDivElement>, 'onScroll'> & {
    as?: React.ElementType | React.FC<React.PropsWithChildren<unknown>>;
    children: React.ReactNode;
  };

export const ModalFlexContent = ({ as = 'div', children, className, ...rest }: Props) => {
  const { spacing } = useThemeValues();
  const Element = as;
  return (
    <Element
      css={(theme: WeaveThemes) => [
        css`
          flex: 1;
          // This allows content to overflow into the padding of the modal box.
          // This is not common, but it is necessary for some edge cases.
          overflow: visible;
          overflow-y: auto;
          position: relative;
          // Give the top and bottom a little padding so that floating labels and outlines don't get cut off
          padding: ${spacing(1)} ${spacing(3)};
          grid-row-start: body-start;
          grid-row-end: body-end;
          display: grid;
          grid-template-columns: minmax(0, 1fr);
          gap: 1rem;
        `,
        styles.smallScrollbar(theme),
      ]}
      className={mergeClasses('wv-modal-flex-content', className)}
      {...rest}
    >
      {children}
    </Element>
  );
};
