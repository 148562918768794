import { lazy, Suspense } from 'react';
import { LazyAssetsFallback, type LazyAssetProps } from '@frontend/internal-assets';

const LazyNoAccessEmptyState = lazy(() => import('./svg-components/no-access-empty-state'));

const NoAccessEmptyState = ({ assetsFallbackProps = {}, customFallback, ...rest }: LazyAssetProps) => (
  <Suspense fallback={customFallback || <LazyAssetsFallback {...assetsFallbackProps} />}>
    <LazyNoAccessEmptyState {...rest} />
  </Suspense>
);

export default NoAccessEmptyState;
