import React, { forwardRef } from 'react';
import { animated } from 'react-spring';
import type { CommonHTMLAttributes } from '@frontend/types';
import { FontColorType } from '../../helpers';
import { ConfirmBadgeIcon, ErrorBadgeIcon, InfoBadgeIcon, WarningBadgeIcon } from '../../icon';
import { useStyles } from '../../use-styles';
import { AlertType } from '../alerts/types';
import { Text } from '../text';

type AlertProps = Omit<CommonHTMLAttributes, 'onClick'> & {
  children: React.ReactNode;
  id?: string;
  onClick?: (id: string) => void;
  type: AlertType;
};

const iconMap = {
  error: ErrorBadgeIcon,
  info: InfoBadgeIcon,
  success: ConfirmBadgeIcon,
  warning: WarningBadgeIcon,
};

const iconColorMap: { [key: string]: FontColorType } = {
  error: 'error',
  info: 'primary',
  success: 'success',
  warning: 'warn',
};

export const Alert = forwardRef<HTMLParagraphElement, AlertProps>(({ children, id, onClick, type, ...rest }, ref) => {
  const Icon = iconMap[type];
  let handleClick;
  if (typeof onClick === 'function') {
    handleClick = () => onClick(id ?? '');
  }
  const containerStyle = useStyles('deprecated_Alert', 'containerStyle');
  const textStyle = useStyles('deprecated_Alert', 'textStyle', { type });
  return (
    <animated.div
      id={id}
      css={containerStyle}
      {...rest}
      aria-live={type === 'error' || type === 'warning' ? 'assertive' : 'polite'}
      onClick={handleClick}
      ref={ref}
    >
      <Text size='medium' css={textStyle}>
        <Icon color={iconColorMap[type]} />
        {children}
      </Text>
    </animated.div>
  );
});
Alert.displayName = 'Alert';
