import { forwardRef } from 'react';
import { css } from '@emotion/react';
import type { CommonHTMLAttributes } from '@frontend/types';

export type OlProps = CommonHTMLAttributes & {
  children: React.ReactNode;
};

export const NakedOl = forwardRef<HTMLOListElement, OlProps>(({ children, ...rest }, ref) => (
  <ol
    css={css`
      list-style-type: none;
      padding-left: 0;
    `}
    {...rest}
    ref={ref}
  >
    {children}
  </ol>
));

NakedOl.displayName = 'NakedOl';
