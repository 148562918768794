import { useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $createTextNode, $getSelection, $isRangeSelection, createCommand } from 'lexical';
import { ActionPressedChip } from '../../atoms';
import { EmojiPickerAction } from '../../molecules/emoji-picker-action';

type EmojiPickerProps = { showCaret?: boolean; className?: string } & Pick<
  React.ComponentProps<typeof ActionPressedChip>,
  'showBoxShadow'
>;
export const INSERT_EMOJI_COMMAND = createCommand('INSERT_EMOJI_COMMAND');
const LOW_PRIORITY = 1;

export const EmojiPicker = ({ showBoxShadow = true, showCaret = true, ...rest }: EmojiPickerProps) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return editor.registerCommand(
      INSERT_EMOJI_COMMAND,
      (payload: string) => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          selection.insertNodes([$createTextNode(payload)]);
        }
        return true;
      },
      LOW_PRIORITY
    );
  }, [editor]);

  return (
    <EmojiPickerAction
      showBoxShadow={showBoxShadow}
      showCaret={showCaret}
      {...rest}
      onSelect={(data) => {
        editor.dispatchCommand(INSERT_EMOJI_COMMAND, data.emoji);
      }}
    />
  );
};
