import React, { forwardRef } from 'react';
import { css } from '@emotion/react';
import type { CommonHTMLAttributes } from '@frontend/types';
import { useDrawerContext } from '../context';

export const DrawerContent = forwardRef<HTMLDivElement, CommonHTMLAttributes & { children: React.ReactNode }>(
  ({ children, id, ...rest }, ref) => {
    const { descriptionId } = useDrawerContext();

    return (
      <div
        ref={ref}
        id={id ?? descriptionId}
        css={css`
          flex: 1;
          overflow-y: auto;
        `}
        {...rest}
      >
        {children}
      </div>
    );
  }
);

DrawerContent.displayName = 'DrawerContent';
