import { contextFactory } from '../../../../hooks/context-factory';

type MultiStepModalContext = {
  disableCloseOnOverlayClick: boolean;
  disableCloseOnEscape: boolean;
  setDisableCloseOnOverlayClick: (disabled: boolean) => void;
  setDisableCloseOnEscape: (disabled: boolean) => void;
  closeModal: () => void;
};

export const [MultiStepModalContext, useMultiStepModalContext] = contextFactory<MultiStepModalContext>();
