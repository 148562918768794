import { ReactNode, forwardRef, type ComponentProps } from 'react';
import { Input, type BaseInputProps } from '../../atoms';
import { FieldLayoutWithAction, FieldWithActionProps } from '../../layouts';

type TextFieldProps = Omit<FieldWithActionProps<'text'>, 'field'> & BaseInputProps;
type Props = TextFieldProps & {
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
};
export const TextField = forwardRef<HTMLDivElement, Props>((props, ref) => (
  <FieldLayoutWithAction
    {...props}
    data-focusable
    //TODO: this might be able to be improved. But this type cast just makes sure the field prop is any of the correct field types
    field={Input as ComponentProps<typeof FieldLayoutWithAction>['field']}
    ref={ref}
  />
));
TextField.displayName = 'TextField';
