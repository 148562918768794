import React from 'react';
import { classNames } from '@frontend/string';
import { MultiStepStepper, MultiStepStepperProps } from './atoms/stepper/stepper';
import { useMultiStepContext } from './multi-step.context';
import { MultiStepControl } from './multi-step.hooks';
import MultiStepProvider, { MultiStepProviderRef } from './multi-step.provider';
import { multiStepStyles } from './multi-step.styles';

export type MultiStepProps = MultiStepControl &
  React.PropsWithChildren<{
    components?: Partial<{
      /**
       * A method accepts stepper props and returns a React node.
       * Use to select a different stepper, or to use default stepper but override
       * the props that are bound by default.
       */
      Stepper: (props?: MultiStepStepperProps) => React.ReactNode | null;
    }>;
  }>;

export type MultiStepRefProps = {
  className?: string;
  style?: React.CSSProperties;
};

export const MultiStepRoot = React.forwardRef<MultiStepProviderRef, MultiStepProps & MultiStepRefProps>(
  ({ className, style, containerRef, components = {}, children, ...props }, ref) => {
    const { Stepper = MultiStepStepper } = components;

    return (
      <div ref={containerRef}>
        <MultiStepProvider ref={ref} containerRef={containerRef} {...props}>
          <MultiStepBody
            className={classNames('multi-step__body-wrapper', className)}
            style={style}
            stepper={<Stepper />}
          >
            {children}
          </MultiStepBody>
        </MultiStepProvider>
      </div>
    );
  }
);

MultiStepRoot.displayName = 'MultiStep';

function MultiStepBody({
  stepper,
  children,
  ...rest
}: React.PropsWithChildren<{
  stepper: React.ReactNode;
}> &
  MultiStepRefProps) {
  const {
    isInModal,
    stepper: { show, display },
  } = useMultiStepContext();

  return (
    <div className='multi-step' css={multiStepStyles.root({ isInModal })} {...rest}>
      {show && stepper}
      <div className='multi-step__body' css={multiStepStyles.body({ showStepper: show, display })}>
        {children}
      </div>
    </div>
  );
}

MultiStepBody.displayName = 'MultiStepBody';
