import { ReactNode } from 'react';
import { useStepperCardContext } from '../provider/stepper-card.provider';
import { useStepperContext } from '../provider/stepper.provider';

export type StepperContentProps = {
  children: ReactNode[] | ReactNode;
  alwaysRender?: boolean;
};

export const StepperContent = ({ children, alwaysRender, ...rest }: Partial<StepperContentProps>) => {
  const { stepStatus, alwaysRender: globalAlwaysRender } = useStepperContext();
  const { step } = useStepperCardContext();

  if (alwaysRender ?? globalAlwaysRender) {
    return (
      <div
        className='step-content'
        {...rest}
        style={{
          display:
            stepStatus[step] === 'currActive' || stepStatus[step] === 'active' || stepStatus[step] === 'errorActive'
              ? 'block'
              : 'none',
        }}
      >
        {children}
      </div>
    );
  }

  return (
    <>
      {(stepStatus[step] === 'currActive' || stepStatus[step] === 'active' || stepStatus[step] === 'errorActive') && (
        <div className='step-content' {...rest}>
          {children}
        </div>
      )}
    </>
  );
};
