import { css } from '@emotion/react';
import { onlyText } from 'react-children-utilities';
import { TableInstance } from 'react-table';
import { Text, PrimaryButton } from '../../';
import { useThemeValues } from '../../../hooks';
import { usePopoverMenu, PopoverMenu, PopoverMenuItem } from '../../popover';
import { emptyStateGraphics } from '../empty-states';
import { TableData } from '../table-data-type';
import { EmptyStateConfig } from '../table-types';

type EmptyStatesProps<T extends TableData> = {
  emptyStateConfig?: EmptyStateConfig<T>;
  tableInstance: TableInstance<T>;
};

export const EmptyStates = <T extends TableData>({ emptyStateConfig, tableInstance }: EmptyStatesProps<T>) => {
  const { spacing, fontSize } = useThemeValues();

  const EmptyStateGraphic = emptyStateConfig?.type
    ? emptyStateGraphics[emptyStateConfig?.type]
    : emptyStateGraphics['generic'];
  return (
    <div className='empty__states__wrapper'>
      <div
        className='empty__state__container'
        css={css`
          position: sticky;
          left: 0;
          display: flex;
          align-items: center;
          padding: ${spacing(5)} 0;
          flex-direction: column;
        `}
      >
        <EmptyStateGraphic
          css={css`
            height: 200px;
          `}
        />
        <div
          css={css`
            margin-top: ${spacing(3)};
          `}
        >
          {typeof emptyStateConfig?.header === 'function' ? (
            emptyStateConfig?.header(tableInstance)
          ) : (
            <Text
              css={css`
                font-size: ${fontSize(20)};
                max-width: 400px;
              `}
              textAlign='center'
              weight='bold'
              color='light'
            >
              {emptyStateConfig?.header ?? 'No data to display'}
            </Text>
          )}
        </div>
        <div
          css={css`
            margin-top: ${spacing(1)};
          `}
        >
          {!!emptyStateConfig?.description &&
            (typeof emptyStateConfig.description === 'function' ? (
              emptyStateConfig.description(tableInstance)
            ) : (
              <Text
                css={css`
                  font-size: ${fontSize(16)};
                  max-width: 400px;
                `}
                textAlign='center'
                color='light'
              >
                {emptyStateConfig?.description}
              </Text>
            ))}
        </div>
        {!!emptyStateConfig?.action && (
          <>
            {!!emptyStateConfig.action.actions ? (
              <EmptyStateMultiActions action={emptyStateConfig?.action} tableInstance={tableInstance} />
            ) : (
              <EmptyStateAction action={emptyStateConfig.action} />
            )}
          </>
        )}
      </div>
    </div>
  );
};

type EmptyStateActionProps<T extends TableData> = {
  action: EmptyStateConfig<T>['action'];
};

const EmptyStateAction = <T extends TableData>({ action }: EmptyStateActionProps<T>) => {
  const { spacing } = useThemeValues();
  if (!action) return null;

  const { label, ...rest } = action;

  return (
    <PrimaryButton
      css={css`
        margin-top: ${spacing(3)};
        margin-bottom: ${spacing(1)};
      `}
      size='tiny'
      {...rest}
    >
      {label}
    </PrimaryButton>
  );
};

type EmptyStateMultiActionsProps<T extends TableData> = {
  action: EmptyStateConfig<T>['action'];
  tableInstance: TableInstance<T>;
};

const EmptyStateMultiActions = <T extends TableData>({ action, tableInstance }: EmptyStateMultiActionsProps<T>) => {
  const { spacing } = useThemeValues();
  const { getTriggerProps, getMenuProps, getItemProps } = usePopoverMenu({ placement: 'top' });

  if (!action?.actions) return null;

  const { label, actions, ...rest } = action;

  const availableActions = actions.filter((action) => !action.hide);
  const hasActionsAvailable = !!availableActions.length;

  return (
    <>
      <PrimaryButton
        css={css`
          margin-top: ${spacing(3)};
          margin-bottom: ${spacing(1)};
        `}
        size='tiny'
        {...(hasActionsAvailable && getTriggerProps())}
        {...rest}
      >
        {label}
      </PrimaryButton>
      {!!hasActionsAvailable && (
        <PopoverMenu {...getMenuProps()}>
          {availableActions.map(({ Icon, label, disabled, onClick, destructive }, index) => (
            <PopoverMenuItem
              key={onlyText(label as React.ReactNode)}
              Icon={Icon}
              {...getItemProps({
                onClick: (e) => {
                  onClick?.(e as any, tableInstance);
                },
                index,
              })}
              disabled={disabled}
              destructive={destructive}
            >
              {label}
            </PopoverMenuItem>
          ))}
        </PopoverMenu>
      )}
    </>
  );
};
