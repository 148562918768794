import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { WeaveThemes } from '../component-theme/theme-types';

const highlight = css`
  animation: highlight 3s ease-in-out;
  @keyframes highlight {
    0%,
    100% {
      background-color: transparent;
    }
    50% {
      background-color: ${theme.colors.tangerine10};
    }
  }
`;

const visuallyHidden = css`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const truncate = css`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const flexCenter = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const multilineTruncate = (lines: number) =>
  css`
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: ${lines};
    overflow-y: hidden;
  `;

const smallScrollbar = (theme: WeaveThemes) => {
  let background, borderRadius;

  if (theme.name === 'spark') {
    background = theme.colors.neutral20;
    borderRadius = theme.borderRadius.small;
  } else if (theme.name === 'original') {
    background = theme.colors.gray400;
    borderRadius = theme.borderRadius.small;
  }

  return css`
    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${background};
      border-radius: ${borderRadius};
    }
  `;
};

export const getAdjustedHeight = (theme: WeaveThemes, baseValue = '100%', isPositioned = true): string | undefined => {
  if (theme.heightOffset === 0) {
    return undefined;
  }

  const heightValue =
    typeof baseValue === 'number'
      ? `${baseValue - theme.heightOffset}px !important`
      : `calc(${baseValue} - ${theme.heightOffset}px) !important`;

  const positionedStyles = isPositioned ? 'bottom: 0 !important; top: auto !important;' : '';

  return `
    height: ${heightValue};
    ${positionedStyles}
  `;
};

/**
 * Set of generally useful styles
 */
export const styles = {
  /**
   * Styled, small scrollbar in webkit browsers
   */
  smallScrollbar,
  /**
   * Adds styles to visually hide an element
   */
  visuallyHidden,
  /**
   * Truncates an element, will add ellipsis on text overflow
   */
  truncate,
  /**
   * Truncates an element based on number of lines given, will add ellipsis on text overflow
   */
  multilineTruncate,
  /**
   * style to center an element horizontally and vertically
   */
  flexCenter,

  /**
   * Adjusts the height of an element based on the theme's height offset
   */
  getAdjustedHeight,

  /**
   * Highlights an element
   */
  highlight,
};
