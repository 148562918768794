import { lazy, Suspense } from 'react';
import { LazyAssetsFallback, type LazyAssetProps } from '@frontend/internal-assets';

const LazyMissing = lazy(() => import('./svg-components/missing'));

const Missing = ({ assetsFallbackProps = {}, customFallback, ...rest }: LazyAssetProps) => (
  <Suspense fallback={customFallback || <LazyAssetsFallback {...assetsFallbackProps} />}>
    <LazyMissing {...rest} />
  </Suspense>
);

export default Missing;
