import { contextFactory } from '../../../../hooks/context-factory';
import { StepData } from '../../multi-step.types';

export type StepContext = {
  stepData: StepData;
  stepIndex: number;
  nextStep: string | null;
  prevStep: string | null;
  isActive: boolean;
  showCancelAction: boolean;
  setStepData: (sd: StepData) => void;
  setValid: (isValid: boolean) => void;
  setComplete: (isComplete: boolean) => void;
};

export const [StepContext, useStepContext] = contextFactory<StepContext>();
