import { useCallback, useRef, useState } from 'react';
import useResizeObserver from '@react-hook/resize-observer';
import { debounce } from 'lodash-es';
import useCallbackRef from './use-callback-ref';

type ScrollProps = {
  scrollTop: number;
  scrollLeft: number;
  scrollWidth: number;
  clientWidth: number;
};

const getIsRight = ({ scrollLeft, scrollWidth, clientWidth }: ScrollProps) => {
  if (Math.ceil(scrollLeft) >= scrollWidth - clientWidth) {
    return true;
  } else {
    return false;
  }
};

const getIsLeft = ({ scrollLeft }: ScrollProps) => {
  if (scrollLeft <= 2) {
    return true;
  } else {
    return false;
  }
};

const getIsTop = ({ scrollTop }: ScrollProps) => {
  if (scrollTop <= 2) {
    return true;
  } else {
    return false;
  }
};

const itIsScrollable = ({ scrollWidth, clientWidth }: ScrollProps) => {
  if (scrollWidth > clientWidth) {
    return true;
  } else {
    return false;
  }
};

export const useScrollShadow = <T extends HTMLElement = HTMLDivElement>(trackClientWidth?: boolean) => {
  const [isRight, setIsRight] = useState(true);
  const [isLeft, setIsLeft] = useState(true);
  const [isTop, setIsTop] = useState(true);
  const [isScrollable, setIsScrollable] = useState(false);
  const [clientWidth, setClientWidth] = useState(0);

  const bodyRef = useRef<HTMLElement | null>(null);

  const handleScroll = useCallback((event: Event) => {
    const currentTarget = event.currentTarget as T;
    window.requestAnimationFrame(() => {
      setIsRight(getIsRight(currentTarget));
      setIsLeft(getIsLeft(currentTarget));
      setIsTop(getIsTop(currentTarget));
      setIsScrollable(itIsScrollable(currentTarget));
      if (trackClientWidth) {
        setClientWidth(currentTarget.clientWidth);
      }
    });
  }, []);

  const callbackRef = useCallbackRef(
    useCallback((node: T) => {
      if (node !== null) {
        setIsRight(getIsRight(node));
        setIsLeft(getIsLeft(node));
        setIsTop(getIsTop(node));
        setIsScrollable(itIsScrollable(node));
        if (trackClientWidth) {
          setClientWidth(node.clientWidth);
        }

        node.addEventListener('scroll', handleScroll);
        bodyRef.current = node;
      }

      return () => node.removeEventListener('scroll', handleScroll);
    }, [])
  );

  useResizeObserver(
    bodyRef,
    debounce(() => {
      if (bodyRef.current) {
        setIsRight(getIsRight(bodyRef.current));
        setIsLeft(getIsLeft(bodyRef.current));
        setIsTop(getIsTop(bodyRef.current));
        setIsScrollable(itIsScrollable(bodyRef.current));
        if (trackClientWidth) {
          setClientWidth(bodyRef.current.clientWidth);
        }
      }
    }, 0)
  );

  return {
    scrollRef: callbackRef,
    isRight,
    isLeft,
    isTop,
    isScrollable,
    clientWidth,
  };
};
