import { css } from '@emotion/react';
import { theme, GRID_UNIT } from '@frontend/theme';
import { MultiStepDisplay } from './multi-step.types';

const STEPPER_ICON_WIDTH = 44;

export type StepperColorProps = {
  hasPrev: boolean;
  isNext: boolean;
  active: boolean;
  complete: boolean;
  valid: boolean;
  index: number;
  percentComplete: number;
  maxIndex: number;
  isClickable: boolean;
  clickDisabled: boolean | undefined;
};
export const multiStepStyles = {
  root: ({ isInModal }) => {
    return css([
      css`
        align-items: stretch;
        min-height: 500px;
        max-height: 100%;
        background-color: ${theme.colors.white};
        max-height: 100%;
        display: flex;
        align-items: stretch;
      `,
      isInModal
        ? css`
            padding: ${theme.spacing(1)} ${theme.spacing(4)};
          `
        : css`
            padding: ${theme.spacing(4)};
            box-shadow: 0px 8px 12px 6px rgba(0, 33, 82, 0.15), 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            border-radius: ${theme.borderRadius.medium};
          `,
    ]);
  },
  stepperWrapper: ({ display }: { scrollDisabled?: boolean; display?: MultiStepDisplay }) =>
    css([
      `
        border-right: solid 1px ${theme.colors.neutral20};
        margin-left: ${display === 'full' ? 0 : -(GRID_UNIT * 2)}px;
        flex: 0 0 auto;
        max-height: 100%;
        max-width: 220px;
        width: ${display === 'full' ? '40%' : '54px'};
    `,
    ]),
  body: ({ showStepper, display }: { showStepper: boolean; display: MultiStepDisplay }) =>
    css(`
        padding-left: ${showStepper ? (display !== 'full' ? theme.spacing(2) : theme.spacing(4)) : '0px'};
        flex-grow: 1;
    `),

  stepper: css(``),
  stepperItem: (props: StepperColorProps) => {
    const cs = multiStepStyles.stepperColors(props);

    const clickStyles = !props.clickDisabled
      ? props.isClickable
        ? css(`
              cursor: pointer;
              &:hover {
                  background-color: ${theme.colors.neutral10};
              }
          `)
        : css(`
              cursor: not-allowed;  
          `)
      : null;

    return css([
      `
        color: ${cs.color};
        font-weight: ${props.active ? 700 : 400};
        height: 64px;
        position: relative;
        margin-right: ${theme.spacing(1)}
      `,
      clickStyles,
    ]);
  },
  stepperIcon: (props: StepperColorProps) => {
    const cs = multiStepStyles.stepperColors(props);

    const size = props.active ? 32 : 24;

    return css(`
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: ${size}px;
            width: ${size}px;
            border: solid 2px ${cs.iconBorder};
            background-color: ${cs.iconBackground};
            color: ${cs.iconColor};
            border-radius: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 15;
            transition: all 0.3s ease;
        `);
  },
  stepperIconText: (props: StepperColorProps) => {
    return css(`
            font-weight: ${props.active ? 700 : 700};    
            font-size: ${props.active ? 18 : 16}px;    
        `);
  },

  stepperIconWrapper: css(`
        width: ${STEPPER_ICON_WIDTH}px;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;  
    `),
  stepperLabel: css(`
        padding-left: ${STEPPER_ICON_WIDTH + GRID_UNIT * 2}px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        max-width: 100%;
        user-select: none;
    `),
  stepperLabelText: css(`
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    `),
  stepProgress: (colorProps: StepperColorProps, isPrimary: boolean) => {
    if (colorProps.index >= colorProps.maxIndex) {
      return undefined;
    }

    const size = colorProps.active ? 32 : 24;
    const scale = 0.6;

    const cs = multiStepStyles.stepperColors(colorProps);
    return css(`
      position: absolute;
      width: 2px;
      height: ${(isPrimary ? colorProps.percentComplete : 100) * scale}%;
      left: 50%;
      transform: translateX(-50%);
      top: calc(50% + ${size / 2}px);    
      background: ${isPrimary ? cs.iconBorder : theme.colors.neutral40};
      transition: height 0.3s ease, background 0.3s ease;
      z-index: 15;
    `);
  },

  stepperColors({ hasPrev, isNext, active, valid }: StepperColorProps) {
    if (hasPrev) {
      return {
        iconBorder: theme.colors.primary50,
        iconBackground: theme.colors.white,
        iconColor: theme.colors.primary50,
        color: theme.colors.neutral90,
      };
    }

    if (isNext && valid) {
      return {
        iconBorder: theme.colors.neutral40,
        iconBackground: theme.colors.white,
        iconColor: theme.colors.neutral40,
        color: theme.colors.neutral40,
      };
    }
    if (active) {
      return {
        iconBorder: theme.colors.primary50,
        iconBackground: theme.colors.primary50,
        iconColor: theme.colors.white,
        color: theme.colors.neutral90,
      };
    }

    // disabled is left
    return {
      iconBorder: theme.colors.neutral40,
      iconBackground: theme.colors.white,
      iconColor: theme.colors.neutral40,
      color: theme.colors.neutral20,
    };
  },

  step: css(`
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        row-gap: ${theme.spacing(2)};    
    `),
  stepHeader: css(`
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 24px;
        font-weight: 700;
        color: ${theme.colors.neutral90};
        flex-shrink: 1;
        column-gap: ${theme.spacing(2)};
    `),
  stepBody: css(`
        flex-grow: 1;
        max-height: 100%;
        overflow-y: auto;
        overflow-x: visible;
    `),
  stepFooter: css(`
        flex-shrink: 1;    
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        column-gap: ${theme.spacing(2)};
    `),
  stepBackButton: css(`
        margin-right: auto;    
    `),
  helpText: css(`
        color: ${theme.colors.neutral50}
    `),
};
