import { Children, FC, useEffect, type ComponentProps } from 'react';
import { css } from '@emotion/react';
import { getBaseFontSize } from '@frontend/theme';
import { useFade } from '../../../../hooks/animations/use-fade';
import { useThemeValues } from '../../../../hooks/use-theme-values';
import { useStyles } from '../../../../use-styles';
import { AnimatedMenu } from '../../../flyouts/animated-menu.component';
import { ListboxChildren, useListboxContext } from '../../../listbox/listbox.provider';
import { StyledListboxList } from '../../../listbox/styled-listbox-list';
import { useListboxSearch } from '../../../listbox/use-listbox-search';
import { DropdownInput } from '../../atoms/dropdown-input.component';
import { DirectionProps } from '../../hooks/types';
import { FieldLayout } from '../../layouts/field-layout.component';
import { BasicFormFieldProps, ExtraFieldProps } from '../../layouts/types';
import { activeDropdownStyle } from '../../list-fields/select-list/old-styles';
import { useDropdownContext } from '../../providers/dropdown-provider';
import { EmptyState } from '../multiselect/multiselect-menu';
import { DropdownIcon } from './dropdown-icon.component';
import { useDropdownSearch } from './use-dropdown-search';
import { getDisplayValue } from './utils';

export type DropdownSearchFieldConfigType = {
  minOptionsForSearch?: number;
  searchFieldPlaceholder?: string;
  showAnimatedDropdown?: boolean;
};

const BASE = getBaseFontSize();

const nonAnimationStyles = (active: boolean): React.CSSProperties => {
  return { visibility: active ? 'visible' : 'hidden' };
};

export type DropdownSearchBaseFieldProps = BasicFormFieldProps<'dropdown'> &
  Pick<ExtraFieldProps, 'containerCss'> & {
    children?: ListboxChildren;
    icon?: FC<React.PropsWithChildren<unknown>>;
  } & DirectionProps &
  DropdownSearchFieldConfigType;

export const DropdownSearchBaseField = ({
  children,
  placeholder = 'Select one',
  icon,
  direction = 'down',
  showAnimatedDropdown = true,
  minOptionsForSearch = 5,
  searchFieldPlaceholder = 'Search',
  ...rest
}: DropdownSearchBaseFieldProps) => {
  const { spacing } = useThemeValues();
  const searchFieldName = `dropdown-search-${rest.name}`;
  const { active, triggerProps, debouncedBlur, debouncedFocus, menuRef } = useDropdownContext();
  const { searchFieldProps, SearchField, matchingChildren } = useListboxSearch(children);
  const showSearch = Children.toArray(children).length >= minOptionsForSearch;
  const fadeMenuStyle = useFade(active);
  const menuStyle = showAnimatedDropdown ? fadeMenuStyle : nonAnimationStyles(active);
  const displayValue = getDisplayValue({
    children: children as ListboxChildren,
    value: rest.value,
  });
  const inputStyles = useStyles('DropdownInput', 'inputStyle', displayValue);
  const dropdownMenuStyle = useStyles('DropdownInput', 'dropdownMenuStyle', {
    active,
    direction,
  });

  const { listboxProps, setActiveItem } = useListboxContext();
  const childrenArray = matchingChildren as [];
  const keydownSearch = useDropdownSearch({
    children: children as ListboxChildren,
    // @ts-ignore types of KeyboardEvent<HTMLUListElement> and KeyboardEvent<Element> no longer match
    onKeyDown: listboxProps.onKeyDown,
    onMatch: setActiveItem,
  });
  const { onChange, ...restProps } = rest;

  useEffect(() => {
    if (active) {
      // make sure to reset field value between menu toggles
      searchFieldProps.onFocus();
      searchFieldProps.onChange({ name: searchFieldName, value: '' });
    }
  }, [active]);

  return (
    <FieldLayout
      //TODO: this might be able to be improved. But this type cast just makes sure the field prop is any of the correct field types
      field={DropdownInput as ComponentProps<typeof FieldLayout>['field']}
      fieldComponentProps={{
        ...triggerProps,
        displayValue,
        css: inputStyles,
      }}
      endAdornment={<DropdownIcon active={active} icon={icon} />}
      css={active && activeDropdownStyle('bottom')}
      placeholder={placeholder}
      {...restProps}
      onChange={onChange}
    >
      {/* @ts-ignore mismatch between event passed into blur */}
      <AnimatedMenu
        aria-labelledby={''}
        as='ul'
        css={[
          dropdownMenuStyle,
          css`
            display: flex;
            flex-direction: column;
          `,
          showSearch &&
            css`
              max-height: ${288 / BASE}rem;
            `,
          !showAnimatedDropdown && !active && noAnimationCheckmarkStyles,
        ]}
        {...restProps}
        ref={menuRef}
        style={menuStyle}
      >
        {showSearch && (
          <SearchField
            {...searchFieldProps}
            onBlur={() => {
              debouncedBlur();
              searchFieldProps.onBlur();
            }}
            onFocus={() => {
              debouncedFocus();
              searchFieldProps.onFocus();
            }}
            placeholder={searchFieldPlaceholder}
            name={searchFieldName}
            css={css`
              margin: ${spacing(1, 2, 0)};
            `}
            tabIndex={active ? undefined : -1}
          />
        )}

        <StyledListboxList
          css={css`
            margin: ${spacing(showSearch ? 1 : 0, 0, 0.5)};
          `}
          {...listboxProps}
          onKeyDown={showSearch ? listboxProps.onKeyDown : keydownSearch}
          tabIndex={active ? 0 : -1}
        >
          {childrenArray.length ? childrenArray : <EmptyState value={searchFieldProps.value} />}
        </StyledListboxList>
      </AnimatedMenu>
    </FieldLayout>
  );
};

// Hiding the visibility of checkbox
const noAnimationCheckmarkStyles = css`
  li > svg {
    visibility: hidden;
  }
`;
