import React from 'react';
import { useTransition, animated } from 'react-spring';
import type { CommonHTMLAttributes } from '@frontend/types';
import { useStyles } from '../../use-styles';
import { Text } from '../text';
import { SpinnerSize, SpinningLoader } from './spinning-loader';

export type ContentLoaderProps = CommonHTMLAttributes & {
  backgroundOpacity?: number;
  children?: React.ReactNode;
  message?: string;
  show?: boolean;
  size?: SpinnerSize;
};

const defaultOpacity = 0.85;

/**
 * Loading overlay for a content block. Will cover the entire area of the closest positioned ancestor element.
 * @param {number} [props.backgroundOpacity] Optional setting to control background opacity. Must be a number between (or including) 0-1
 * @param {string} [props.message] Optional message to show below the loader.
 * @param {boolean} [props.show] Toggles loader visibility.
 */
export const ContentLoader = ({
  backgroundOpacity = defaultOpacity,
  children,
  message,
  show,
  size,
  ...rest
}: ContentLoaderProps) => {
  const transitions = useTransition(show, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });
  let opacity = backgroundOpacity;
  if (!(opacity > 0 && opacity < 1)) opacity = defaultOpacity;
  const style = useStyles('Loaders', 'contentLoaderStyle', { opacity });
  const textStyle = useStyles('Loaders', 'contentLoaderTextStyle');
  return (
    <React.Fragment>
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <animated.div css={style} {...rest} key={key} style={props}>
              <SpinningLoader key='loader' size={size} />
              {!!message && (
                <Text key='message' textAlign='center' css={textStyle}>
                  {message}
                </Text>
              )}
              {children}
            </animated.div>
          )
      )}
    </React.Fragment>
  );
};
