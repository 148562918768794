import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import EmojiPicker, { EmojiClickData } from 'emoji-picker-react';
import { theme } from '@frontend/theme';
import { EmojiIcon } from '../../../icon';
import { PopoverDialog, usePopoverDialog } from '../../popover';
import { ActionDropdownChip, ActionPressedChip } from '../atoms';

type EmojiPickerActionProps = {
  onSelect: (selection: EmojiClickData) => void;
  showCaret?: boolean;
} & Pick<React.ComponentProps<typeof ActionPressedChip>, 'showBoxShadow' | 'compact' | 'css' | 'isActive'>;

export const EmojiPickerAction = ({
  showBoxShadow = true,
  compact = true,
  isActive,
  onSelect,
  showCaret,
  ...rest
}: EmojiPickerActionProps) => {
  const EMOJI_PICKER_WIDTH = 350;
  const EMOJI_PICKER_HEIGHT = 450;
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const {
    getDialogProps: getEmojiDialogProps,
    getTriggerProps: getEmojiTriggerProps,
    isOpen: emojiDialogIsOpen,
    close: closeEmojiDialog,
  } = usePopoverDialog({
    placement: 'bottom-start',
    initialOffset: {
      x: -252,
      y: 0,
    },
  });

  const handleSelect = (emoji: EmojiClickData) => {
    onSelect(emoji);
    setShowEmojiPicker(false);
    closeEmojiDialog();
  };

  useEffect(() => {
    let id: ReturnType<typeof setTimeout>;

    if (!emojiDialogIsOpen) {
      setShowEmojiPicker(false);
    } else {
      id = setTimeout(() => setShowEmojiPicker(true), 400);
    }

    return () => {
      if (id) {
        clearTimeout(id);
      }
    };
  }, [emojiDialogIsOpen]);

  return (
    <>
      <ActionDropdownChip
        showBoxShadow={showBoxShadow}
        isOpen={emojiDialogIsOpen}
        isActive={emojiDialogIsOpen}
        compact={compact}
        showCaret={showCaret}
        {...getEmojiTriggerProps()}
        {...rest}
      >
        <EmojiIcon
          size={24}
          css={css`
            min-width: ${theme.spacing(2.5)};
            padding: ${theme.spacing(0.25, 0)};
          `}
        />
      </ActionDropdownChip>
      <PopoverDialog
        {...getEmojiDialogProps()}
        css={css`
          width: ${EMOJI_PICKER_WIDTH}px;
          max-height: ${EMOJI_PICKER_HEIGHT};
          padding: 0;
          border-radius: ${theme.borderRadius.medium};
        `}
        returnFocus={false}
      >
        <div
          css={css`
            width: ${EMOJI_PICKER_WIDTH}px;
            height: ${EMOJI_PICKER_HEIGHT}px;
            display: flex;
            justify-content: center;
            align-items: center;
          `}
        >
          {showEmojiPicker && (
            <EmojiPicker
              css={css`
                font-family: ${theme.font.family};
              `}
              onEmojiClick={handleSelect}
              lazyLoadEmojis
              height={EMOJI_PICKER_HEIGHT}
              width={EMOJI_PICKER_WIDTH}
            />
          )}
        </div>
      </PopoverDialog>
    </>
  );
};
