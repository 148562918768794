import type { CommonHTMLAttributes } from '@frontend/types';
import { getDescriptorId } from './utils';

export interface FieldDescriptorProps extends CommonHTMLAttributes {
  /** This value is the id for the field described by this element. */
  id: string;
}

export const FieldDescriptor: React.FC<React.PropsWithChildren<FieldDescriptorProps>> = ({ children, id, ...rest }) => (
  <span id={getDescriptorId(id)} {...rest}>
    {children}
  </span>
);
