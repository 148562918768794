import { forwardRef, type FC, type ReactNode } from 'react';
import { css } from '@emotion/react';
import { isFunction } from 'lodash-es';
import type { CommonHTMLButtonAttributes } from '@frontend/types';
import { useThemeValues } from '../../hooks';
import { useStyles } from '../../use-styles';
import { Button } from '../button';

type Props = CommonHTMLButtonAttributes & {
  children: ReactNode;
  disabled?: boolean;
  icon?: FC<React.PropsWithChildren<unknown>>;
  trackingId?: string;
  variant?: 'rounded' | 'rounded-small';
} & { color?: string };

/** @deprecated Instead, please use `<Button variant='secondary'>` from `@frontend/design-system` */
export const TextButton = forwardRef<HTMLButtonElement, Props>(
  ({ children, icon, trackingId, variant, ...rest }, ref) => {
    const hasIcon = isFunction(icon);
    const IconComponent = icon as FC<React.PropsWithChildren<unknown>>;
    const styles = useStyles('TextButton', { hasIcon, variant });
    const theme = useThemeValues();

    return theme.name === 'spark' ? (
      <Button
        css={[
          hasIcon &&
            css`
              align-items: center;
              display: flex;

              svg {
                margin-right: ${theme.spacing(1)};
              }
            `,
        ]}
        destructive={rest?.color === 'critical' ? true : false}
        variant='tertiary'
        type='button'
        {...rest}
        ref={ref}
        data-trackingid={trackingId}
      >
        {!!icon && <IconComponent />}
        {children}
      </Button>
    ) : (
      <button css={styles} type='button' {...rest} ref={ref} data-trackingid={trackingId}>
        {!!icon && <IconComponent />}
        {children}
      </button>
    );
  }
);
TextButton.displayName = 'TextButton';
