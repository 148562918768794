import React, { PropsWithChildren } from 'react';
import { css } from '@emotion/react';
import { Tray, TrayProps, Button, ButtonBar } from '../../';
import { useThemeValues } from '../../../hooks';
import { styles } from '../../../styles';
import { useStyles } from '../../../use-styles';
import { ModalControlModalProps } from '../../modal';
import { FilterSidebarHeader } from '../column-filters/atoms';
import { ManualFilterSection } from './manual-filter-section';

type FormProps = {
  ref: React.MutableRefObject<HTMLFormElement | null>;
  noValidate: boolean;
  onSubmit: (event: React.FormEvent) => void;
};

type ClickHandler = (event?: React.PointerEvent | React.FormEvent) => void | Promise<void>;

type ManualFiltersProps = ModalControlModalProps &
  PropsWithChildren<{
    formProps?: FormProps;
    onApplyClick?: ClickHandler;
    onClose?: ModalControlModalProps['onClose'];
    onClearAllClick?: ClickHandler;
    applyLabel?: string;
    clearAllLabel?: string;
    disableApplyButton?: boolean;
    disableClearAllButton?: boolean;
    headerLabel?: string;
    width?: TrayProps['width'];
    trackingId?: string;
  }>;

const ManualTableFilters = ({
  formProps,
  onApplyClick,
  onClearAllClick,
  applyLabel,
  headerLabel,
  clearAllLabel,
  disableApplyButton = false,
  disableClearAllButton = false,
  width = 'small',
  children,
  trackingId = 'tracking-id-base-not-set',
  // onClose,
  ...modalProps
}: ManualFiltersProps) => {
  const theme = useThemeValues();
  const buttonBarStyles = useStyles('TableCommonStyles', 'tableFilterStyles', {
    type: 'filterSidebarButtons',
  });

  const handleApplyClick = (e) => {
    onApplyClick?.(e);
    handleSidebarClose();
  };

  const handleSidebarClose = () => {
    // onClose?.();
    modalProps.onClose();
  };

  const ConditionalFormsComponent = formProps ? 'form' : 'section';

  return (
    <Tray
      /*The "+1" is included because filters should appear above the modal when the table is rendered on a modal. */
      zIndex={theme.zIndex.modals + 1}
      width={width}
      css={{ padding: 0 }}
      {...modalProps}
    >
      <div
        css={css`
          height: 100%;
          display: flex;
          flex-direction: column;
        `}
      >
        <FilterSidebarHeader filterSidebarHeaderLabel={headerLabel} closeFilterSidebar={handleSidebarClose} />
        <ConditionalFormsComponent
          {...formProps}
          id='table-filters'
          css={[
            css`
              flex: 1;
              overflow-y: auto;
            `,
            styles.smallScrollbar(theme),
          ]}
        >
          {children}
        </ConditionalFormsComponent>
        <ButtonBar css={buttonBarStyles}>
          {!!onClearAllClick && (
            <Button
              variant='secondary'
              trackingId={`${trackingId}-filter-clear`}
              disabled={disableClearAllButton}
              onClick={(e) => onClearAllClick?.(e)}
            >
              {clearAllLabel ?? 'Clear All Filters'}
            </Button>
          )}
          <Button
            disabled={disableApplyButton}
            form='table-filters'
            type={formProps ? 'submit' : 'button'}
            onClick={handleApplyClick}
            trackingId={`${trackingId}-filter-apply`}
          >
            {applyLabel ?? 'Apply Filters'}
          </Button>
        </ButtonBar>
      </div>
    </Tray>
  );
};

const ManualFiltersNamespace = Object.assign(ManualTableFilters, {
  Section: ManualFilterSection,
});

export { ManualFiltersNamespace as TableFilters };
