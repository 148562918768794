import { memo } from 'react';
import { css } from '@emotion/react';
import { motion, AnimatePresence } from 'motion/react';
import { useMatchMedia, breakpoints } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import { CheckIconSmall } from '../../../icon';
import { Heading } from '../../heading';
import { Text } from '../../text';
import { useTooltip } from '../../tooltip';
import { Status, useStepperContext } from '../provider/stepper.provider';
import {
  stepperDuration,
  stepperDelay,
  activeStepperDelay,
  inactiveStepperDuration,
  stepperLineWidth,
} from '../stepper.styles';

export type StepperHorizontalProps = {
  numSteps: number;
  stepTitleText?: {
    [key: number]: string;
  };
};

export const StepperHorizontal = ({ numSteps, stepTitleText }: StepperHorizontalProps) => {
  const { stepStatus, handleStepperCard } = useStepperContext();
  const stepArray: number[] = [];
  for (let i = 1; i <= numSteps; i++) {
    stepArray.push(i);
  }

  return (
    <StepperHorizontalRaw
      steps={stepArray.map((step) => ({ title: stepTitleText?.[step], status: stepStatus[step]! }))}
      onStepClick={handleStepperCard}
    />
  );
};

const stepStyles = {
  active: {
    backgroundColor: theme.colors.primary50,
    border: `2px solid ${theme.colors.primary50}`,
    color: theme.colors.white,
  },
  inactive: {
    backgroundColor: theme.colors.white,
    color: theme.colors.neutral40,
    border: `2px solid ${theme.colors.neutral40}`,
  },
  initial: {
    backgroundColor: theme.colors.neutral40,
    color: theme.colors.primary50,
    border: `2px solid ${theme.colors.neutral40}`,
  },
  completed: {
    color: theme.colors.primary50,
    backgroundColor: theme.colors.white,
    border: `2px solid ${theme.colors.primary50}`,
    transition: {
      delay: 0,
      duration: 0.5,
    },
  },
  currActive: {
    backgroundColor: theme.colors.primary50,
    border: `2px solid ${theme.colors.primary50}`,
    color: theme.colors.white,
  },
  errorActive: {
    backgroundColor: theme.colors.status.critical,
    border: `2px solid ${theme.colors.status.critical}`,
    color: theme.colors.white,
  },
  error: {
    backgroundColor: theme.colors.status.critical,
    border: `2px solid ${theme.colors.status.critical}`,
    color: theme.colors.white,
  },
};

const stepLineStyles = {
  active: {
    width: '50%',
    transition: {
      delay: 1,
    },
  },
  currActive: {
    width: '50%',
    transition: {
      delay: 1,
    },
  },
  errorActive: {
    width: '50%',
    transition: {
      delay: 1,
    },
  },
  completed: {
    width: '100%',
    transition: {
      delay: 0,
    },
  },
};

type Step = {
  title: string | undefined;
  status: Status;
  stepDescription?: string;
};

type StepperHorizontalRawProps = {
  steps: Step[];
  onStepClick: (step: number) => void;
  showStepCount?: boolean;
  className?: string;
};

export const StepperHorizontalRaw = ({
  className,
  steps,
  onStepClick,
  showStepCount = true,
}: StepperHorizontalRawProps) => {
  if (steps.length === 0) return null;

  return (
    <div
      className={`stepper-horizontal ${className}`}
      css={css`
        display: flex;
        align-items: flex-start;
        justify-content: center;
      `}
    >
      {steps.map((step, i) => (
        <HorizontalStepperItem
          key={i}
          step={step}
          currentStepIndex={i}
          onStepClick={onStepClick}
          totalStepsCount={steps.length}
          showStepCount={showStepCount}
        />
      ))}
    </div>
  );
};

type HorizontalStepperItemProps = {
  step: Step;
  onStepClick: (step: number) => void;
  showStepCount?: boolean;
  currentStepIndex: number;
  totalStepsCount: number;
};

const HorizontalStepperItem = memo(
  ({ currentStepIndex, onStepClick, step, totalStepsCount, showStepCount }: HorizontalStepperItemProps) => {
    const isMobile = useMatchMedia({ maxWidth: breakpoints.xsmall.max });
    const { tooltipProps, triggerProps, Tooltip } = useTooltip();
    const handleStepClick = () => onStepClick(currentStepIndex);
    const stepNumber = currentStepIndex + 1;

    return (
      <div
        css={css`
          display: flex;
          flex-direction: column;
          width: ${100 / totalStepsCount}%;
        `}
        className='stepper-horizontal-step'
      >
        <div css={styles.stepBox(step.status)} className='step-box'>
          {step.stepDescription && <Tooltip {...tooltipProps}>{step.stepDescription}</Tooltip>}
          <motion.div
            animate={step.status}
            variants={stepStyles}
            transition={{ ease: 'easeOut', duration: 0.5 }}
            className='step-num-horizontal'
            css={styles.stepNumHorizontal(step.status)}
            onClick={handleStepClick}
            {...triggerProps}
          >
            {step.status === 'completed' ? (
              <AnimatePresence>
                <CheckIconSmall />
              </AnimatePresence>
            ) : (
              showStepCount && <Heading level={2}>{stepNumber}</Heading>
            )}
          </motion.div>
          <div className='step-line-horizontal inactive'>
            <motion.div
              animate={step.status}
              variants={stepLineStyles}
              transition={{ ease: 'easeOut', duration: 0.5 }}
              className='step-line-horizontal'
              css={styles.stepLineHorizontal(step.status)}
            ></motion.div>
          </div>
        </div>
        {step.title && (
          <Text className='step-title-text' css={styles.stepTitleText(isMobile, step.status)} onClick={handleStepClick}>
            {step.title}
          </Text>
        )}
      </div>
    );
  }
);

const styles = {
  stepTitleText: (isMobile: boolean, stepStatus: Status) => [
    css`
      margin: ${theme.spacing(0, 1)};
      line-height: 1.25;
      text-align: center;
      min-height: 40px;
    `,
    isMobile &&
      css`
        display: none;
      `,
    stepStatus !== 'inactive' &&
      css`
        &:hover {
          cursor: pointer;
          color: ${theme.colors.primary50};
        }
      `,
  ],
  stepBox: (stepStatus: Status) => [
    css`
      height: 32px;
      margin-bottom: ${theme.spacing(2)};
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      transform: translateX(calc(50% - 12px));
      .step-line-horizontal.inactive,
      .step-line-horizontal.error {
        height: 2px;
        position: absolute;
        left: ${stepStatus === 'active' || stepStatus === 'currActive' || stepStatus === 'errorActive'
          ? '32px'
          : '24px'};
        width: calc(100% - 24px);
        background-color: ${theme.colors.neutral40};
      }
    `,
    stepStatus === 'active' &&
      css`
        transform: translateX(calc(50% - 16px));
      `,
  ],
  stepLineHorizontal: (stepStatus: Status) => [
    css`
      height: 2px;
      position: absolute;
      background-color: ${theme.colors.neutral40};
    `,
    stepStatus === 'completed' &&
      css`
        background-color: ${theme.colors.primary50};
      `,
    (stepStatus === 'active' || stepStatus === 'currActive' || stepStatus === 'errorActive') &&
      css`
        background-color: ${theme.colors.primary50};
        top: calc(50% - 1px);
        position: absolute;
      `,
    (stepStatus === 'errorActive' || stepStatus === 'error') &&
      css`
        background-color: ${theme.colors.status.critical};
      `,
  ],
  stepNumHorizontal: (stepStatus: Status) => [
    css`
      color: ${theme.colors.primary50};
      background-color: ${theme.colors.white};
      border: 2px solid ${theme.colors.neutral40};
      border-radius: 50%;
      width: 24px;
      height: 24px;
      left: ${theme.spacing(-4)};
      p,
      h2 {
        line-height: 1;
        padding-top: 2px;
        font-size: ${theme.fontSize(16)};
        text-align: center;
        color: ${theme.colors.neutral40};
        margin: 0;
      }
    `,
    (stepStatus === 'inactive' || stepStatus === 'error') &&
      css`
        transition-delay: 0s;
        transition-duration: ${inactiveStepperDuration};
        transition-property: background-color, border, width, height, padding, transform, margin-left;
        p,
        h2 {
          transition-delay: 0s;
          transition-duration: ${inactiveStepperDuration};
          transition-property: color, line-height, font-size;
        }
      `,
    stepStatus === 'completed' &&
      css`
        color: ${theme.colors.primary50};
        background-color: ${theme.colors.white};
        border: 2px solid ${theme.colors.primary50};
        width: 24px;
        height: 24px;
        padding: ${stepperLineWidth};
        cursor: pointer;
        p,
        h2 {
          color: ${theme.colors.neutral40};
        }
      `,
    (stepStatus === 'active' || stepStatus === 'currActive' || stepStatus === 'errorActive') &&
      css`
        background-color: ${theme.colors.primary50};
        border: 2px solid ${theme.colors.primary50};
        width: 32px;
        min-width: 32px;
        height: 32px;
        min-height: 32px;
        padding: 2px;
        transition-delay: ${stepperDelay};
        transition-duration: ${stepperDuration};
        transition-property: background-color, border, width, height, padding, transform;
        p,
        h2 {
          padding-top: 3px;
          font-size: ${theme.fontSize(18)};
          font-weight: ${theme.font.weight.bold};
          color: ${theme.colors.white};
          transition-delay: ${activeStepperDelay};
          transition-duration: ${stepperDuration};
          transition-property: color, line-height;
        }
      `,
    (stepStatus === 'errorActive' || stepStatus === 'error') &&
      css`
        background-color: ${theme.colors.status.critical};
        border: 2px solid ${theme.colors.status.critical};
        p,
        h2 {
          color: ${theme.colors.white};
        }
      `,
  ],
};
