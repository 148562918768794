import { useState, useCallback, useRef } from 'react';

type ElementType = HTMLElement;
type RefCallback = (element: ElementType | null) => void;

export const useContentOverflow = (): [RefCallback, boolean] => {
  const [isTruncated, setIsTruncated] = useState<boolean>(false);
  const observerRef = useRef<ResizeObserver | null>(null);

  const checkTruncation = useCallback((element: ElementType) => {
    if (!element) return;

    const isTruncatedNow = element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;

    setIsTruncated(isTruncatedNow);
  }, []);

  const setRef = useCallback(
    (element: ElementType | null) => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }

      if (!element) {
        observerRef.current = null;
        return;
      }

      checkTruncation(element);

      observerRef.current = new ResizeObserver(() => {
        checkTruncation(element);
      });

      observerRef.current.observe(element);
    },
    [checkTruncation]
  );

  return [setRef, isTruncated];
};
