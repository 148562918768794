import React, { KeyboardEventHandler, MouseEventHandler, useEffect, useRef } from 'react';
import { autoUpdate } from '@floating-ui/react';
import { onlyText } from 'react-children-utilities';
import CopyToClipboard from 'react-copy-to-clipboard';
import { theme } from '@frontend/theme';
import type { IconProps } from '../../icon';
import { CopyIcon } from '../../icon';
import { PopoverMenu, PopoverMenuItem, PropGettersMenu } from '../popover/menu';
import { conditionallyStopPropagation, usePopover } from '../popover/use-popover';

type ContextMenuAction = {
  hide?: boolean;
  disabled?: boolean;
  Icon?: React.FC<React.PropsWithChildren<IconProps>>;
  label: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  destructive?: boolean;
  trackingId?: string;
  hoverLabel?: string;
};

export type ContextMenuActions = ContextMenuAction[];

type ContextMenuProps = { actions: ContextMenuActions; children: React.ReactElement; returnFocus?: boolean };

let timeout: number;

export const ContextMenu = ({ actions, children, returnFocus }: ContextMenuProps) => {
  const childCount = React.Children.count(children);

  if (childCount !== 1) {
    throw new Error('ContextMenu component requires exactly one child.');
  }

  const availableActions = actions.filter((action) => !action.hide);
  const hasActionsAvailable = !!availableActions.length;

  const allowMouseUpCloseRef = useRef(false);

  const {
    x,
    y,
    nodeId,
    context,
    activeIndex,
    isOpen,
    refs,
    listItemsRef,
    listContentRef,
    setIsOpen,
    getFloatingProps,
    getItemProps,
  } = usePopover<HTMLElement>({
    middlewareOptions: {
      flip: {
        fallbackPlacements: ['left-start'],
      },
      shift: {
        padding: 10,
      },
      offset: { mainAxis: 5, alignmentAxis: 4 },
    },
    interactionOptions: {
      typeahead: { enabled: true },
    },
    omitSizeMiddleware: true,
    whileElementsMounted: autoUpdate,
  });

  useEffect(() => {
    function onMouseUp() {
      if (allowMouseUpCloseRef.current) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mouseup', onMouseUp);
    return () => {
      document.removeEventListener('mouseup', onMouseUp);
      clearTimeout(timeout);
    };
  }, [refs]);

  function onContextMenu(e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();

    if (!hasActionsAvailable) return;

    refs.setPositionReference({
      getBoundingClientRect() {
        return {
          width: 0,
          height: 0,
          x: e.clientX,
          y: e.clientY,
          top: e.clientY,
          right: e.clientX,
          bottom: e.clientY,
          left: e.clientX,
        };
      },
    });

    setIsOpen(true);
    clearTimeout(timeout);

    allowMouseUpCloseRef.current = false;
    timeout = window.setTimeout(() => {
      allowMouseUpCloseRef.current = true;
    }, 300);
  }

  const getMenuProps = ((params) => {
    const { onClick, onKeyDown, ...rest } = params ?? {};

    const internalClickHandler: MouseEventHandler<HTMLMenuElement> = (e) => {
      conditionallyStopPropagation(e);
      onClick?.(e);
    };

    const internalKeyDownHandler: KeyboardEventHandler<HTMLMenuElement> = (e) => {
      conditionallyStopPropagation(e);
      onKeyDown?.(e);
    };

    return {
      ...getFloatingProps({
        ref: refs.setFloating,
        style: {
          position: 'fixed',
          top: y ?? '',
          left: x ?? '',
        },
        onKeyDown: internalKeyDownHandler,
        onClick: internalClickHandler,
        onMouseUp: (e) => e.stopPropagation(),
        ...rest,
      }),
      nodeId,
      context,
      isOpen,
    };
  }) as PropGettersMenu<HTMLMenuElement, false>['getMenuProps'];

  const getItemsProps = ((params) => {
    const { onClick, onKeyDown, disableCloseOnSelect, ...rest } = params ?? {};

    const internalClickHandler: MouseEventHandler<HTMLButtonElement> = (e) => {
      conditionallyStopPropagation(e);
      onClick?.(e);
      if (!disableCloseOnSelect) {
        setIsOpen(false);
      }
    };

    const internalKeyDownHandler: KeyboardEventHandler<HTMLButtonElement> = (e) => {
      conditionallyStopPropagation(e);
      if (e.key === 'Enter' || e.key === ' ') {
        onKeyDown?.(e);
        if (!disableCloseOnSelect) {
          setIsOpen(false);
        }
      }
    };

    return {
      ...getItemProps({
        ref(node) {
          if (params && Number.isInteger(params.index)) {
            listItemsRef.current[params.index] = node;
            listContentRef.current[params.index] = node?.textContent ?? null;
          }
        },
        onClick: internalClickHandler,
        onKeyDown: internalKeyDownHandler,
        ...rest,
      }),
      active: activeIndex === params?.index,
    };
  }) as PropGettersMenu<HTMLButtonElement, false>['getItemProps'];

  const selection = window.getSelection?.();
  const selectedText = selection?.toString?.() ?? '';
  const selectedTextLength = selectedText?.length ?? 0;

  return (
    <>
      {React.cloneElement(children, { onContextMenu })}
      <PopoverMenu initialFocus={refs.floating} {...getMenuProps()} returnFocus={returnFocus}>
        {hasActionsAvailable &&
          availableActions.map(({ Icon, label, disabled, onClick, destructive, trackingId, hoverLabel }, index) => (
            <PopoverMenuItem
              key={onlyText(label as React.ReactNode)}
              Icon={Icon}
              {...getItemsProps({
                onClick: (e) => {
                  onClick?.(e);
                },
                index,
              })}
              disabled={disabled}
              destructive={destructive}
              trackingId={trackingId}
              hoverLabel={hoverLabel}
            >
              {label}
            </PopoverMenuItem>
          ))}
        {!!selectedTextLength && (
          <CopyToClipboard text={selectedText}>
            <PopoverMenuItem
              Icon={CopyIcon}
              {...getItemsProps({
                index: availableActions?.length ?? 0,
              })}
              style={{ borderTop: `1px solid ${theme.colors.neutral10}` }}
            >
              Copy Text
            </PopoverMenuItem>
          </CopyToClipboard>
        )}
      </PopoverMenu>
    </>
  );
};
