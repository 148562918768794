import { css } from '@emotion/react';
import {
  listRowContentColumnTheme,
  listRowContentColumnThemeOriginal,
} from './atoms/content-column/list-row-content-column-theme';
import { ContentColumnStyleProps, contentColumnStyles } from './atoms/content-column/list-row-content-column.styles';
import {
  trailingColumnTheme,
  trailingColumnThemeOriginal,
} from './atoms/trailing-column/list-row-trailing-column-theme';
import { trailingColumnStyles } from './atoms/trailing-column/list-row-trailing-column.styles';
import { TrailingColumnComponentProps } from './atoms/trailing-column/types';
import { listRowTheme, listRowThemeOriginal, ListRowThemeValues } from './list-theme';

export type ListRowStylesProps = {
  isClickable?: boolean;
  isSelected?: boolean;
};
const listRowStyles = (
  { borderColor, spacing, primaryBlue, selectedBackground, hoverBackground }: ListRowThemeValues,
  { isClickable, isSelected }: ListRowStylesProps
) => [
  css`
    position: relative;
    width: 100%;
    border-bottom: 1px solid ${borderColor};
    display: grid;
    grid-template-areas: 'lead content trail';
    grid-template-columns: min-content;
    padding: ${spacing(2)};
    transition: background-color 250ms ease-out;
    :focus-visible {
      outline: 1px solid ${primaryBlue};
    }
  `,
  isClickable &&
    css`
      :hover {
        background-color: ${hoverBackground};
        cursor: pointer;
      }
    `,
  isSelected &&
    css`
      background-color: ${selectedBackground};
    `,
];

const listRowIndicatorStyles = () => css`
  position: absolute;
  left: 0;
  top: 0;
  width: 8px;
  height: 100%;
  background-color: ${listRowTheme.primaryBlue};
`;

export const ListRowStyles = {
  ListRow: {
    Indicator: () => listRowIndicatorStyles(),
    Row: (props: ListRowStylesProps) => listRowStyles(listRowTheme, props),
    ContentColumn: (props: ContentColumnStyleProps) => contentColumnStyles(listRowContentColumnTheme, props),
    TrailingColumn: (props: TrailingColumnComponentProps) => trailingColumnStyles(trailingColumnTheme, props),
  },
};

export const ListRowStylesOriginal: typeof ListRowStyles = {
  ListRow: {
    Indicator: () => listRowIndicatorStyles(),
    Row: (props: ListRowStylesProps) => listRowStyles(listRowThemeOriginal, props),
    ContentColumn: (props: ContentColumnStyleProps) => contentColumnStyles(listRowContentColumnThemeOriginal, props),
    TrailingColumn: (props: TrailingColumnComponentProps) => trailingColumnStyles(trailingColumnThemeOriginal, props),
  },
};
