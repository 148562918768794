import { lazy, Suspense } from 'react';
import { LazyAssetsFallback, type LazyAssetProps } from '@frontend/internal-assets';

const LazyEmptyStateServiceQuality = lazy(() => import('./svg-components/empty-state-service-quality'));

const EmptyStateServiceQuality = ({ assetsFallbackProps = {}, customFallback, ...rest }: LazyAssetProps) => (
  <Suspense fallback={customFallback || <LazyAssetsFallback {...assetsFallbackProps} />}>
    <LazyEmptyStateServiceQuality {...rest} />
  </Suspense>
);

export default EmptyStateServiceQuality;
