import type { CommonHTMLLabelAttributes } from '@frontend/types';
import { getLabelId } from './utils';

export interface LabelProps extends CommonHTMLLabelAttributes {
  /** This value is the id for the field labelled by this element.  */
  id: string;
}

export const Label: React.FC<React.PropsWithChildren<LabelProps>> = ({ children, id, ...rest }) => (
  <label id={getLabelId(id)} htmlFor={id} {...rest}>
    {children}
  </label>
);
